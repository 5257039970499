import { Map } from 'immutable';
import AdminAuthActions from '../actions/AdminAuthAction';

const initialState = {
  userData: {},
  userLogError: {},
  userType: '',
  userId: '',
};

export default function AdminAuthData(state = initialState, action) {
  switch (action.type) {
    case AdminAuthActions.USER_LOGGED_DATA:
      return { ...state, userData: action?.payload };
    case AdminAuthActions.USER_LOGGED_ERROR:
      return { ...state, userLogError: action?.payload };
    case AdminAuthActions.USER_TYPE_DATA:
      return { ...state, userType: action?.payload };
    case AdminAuthActions.USER_ID_DATA:
      return { ...state, userId: action?.payload };
    case AdminAuthActions.USER_RESET_TOKEN:
      return { ...state, resetToken: action?.payload };
    case AdminAuthActions.USER_RESET_TOKEN_ERROR:
      return { ...state, resetTokenError: action?.payload };
      case AdminAuthActions.USER_RESET_TOKEN_VALIDATION:
        return { ...state, passwordChangedData: action?.payload };
        case AdminAuthActions.USER_RESET_TOKEN_VALIDATION_ERROR:
          return { ...state, passwordChangedError: action?.payload };
    default:
      return { ...state };
  }
}
