import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageContainer from '../../UIElements/Containers';
import { resetWorkSiteWorkOrderSummaryErrorData, getWorksiteWorkOrderSummary, getSingleWorksite } from '../../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { FiShare } from 'react-icons/fi';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import GroupSelect from '../../Forms/Elements/GroupSelect';
import { nullToEmpty } from '../../../utils/csvExport';
import { Link } from 'react-router-dom';

const SummaryVisits = () => {

  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');

  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'completed', label: 'Completed' }, { value: 'requires authorization', label: 'Requires Authorization' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires part', label: 'Requires Part' }]
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const siteFilterOptions = [{ value: 'all sites', label: 'All Sites' }, { value: '0', label: 'Active Sites' }, { value: '1', label: 'InActive Sites' }]

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  const dispatch = useDispatch();
  const callRecords = useSelector((state) => state.WorkSiteData.getWorksiteWorkOrderSummaryData);
  const callRecordsErrorData = useSelector((state) => state.WorkSiteData.getWorksiteWorkOrderSummaryError);
  const worksiteId = useSelector((state) => state.AdminAuthData.userId);

  const [WorkOrderSummaryWithIndex, setWorkOrderSummaryWithIndex] = useState([]);
  const [workerStatus, setWorkerStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [sites, setSites] = useState('0');

  const [filters, setFilters] = useState({
    searchValue: '',
    workerStatus: '',
    workOrderNumber: ''
  });

  // GET Actions
  useEffect(() => {
    setIsLoading(true)
    if (worksiteId) {
      dispatch(getWorksiteWorkOrderSummary(jwtToken, worksiteId));
      dispatch(getSingleWorksite(jwtToken, worksiteId));
    }
  }, [worksiteId]);

  useEffect(() => {
    setWorkOrderSummaryWithIndex(nullToEmpty(callRecords));
    
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [callRecords]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetWorkSiteWorkOrderSummaryErrorData());
      setIsLoading(false)
    }
  }, [callRecordsErrorData]);

  useEffect(() => {
    const { workOrderNumber } = filters; // Extract phoneNumber and workerName filters

    setTimeout(() => {
      setIsLoading(false)
    });

    let filteredData = callRecords;
    

    if (workOrderNumber) {
      filteredData = filteredData.filter(data =>
        data.workOrderNumber?.toLowerCase().includes(workOrderNumber.toLowerCase())

      );
    }

    if (workerStatus?.length > 0 && workerStatus && workerStatus !== 'all') {
      filteredData = filteredData.filter(data => {
        const lastRecord = data.records[data.records.length - 1]; // Get the last record

        // Ensure that lastRecord, worksite, and workerStatus exist
        if (!lastRecord || !lastRecord || !lastRecord.workerStatus) {
          return false;
        }

        // Check if the last record's worker status matches the input workerStatus
        return lastRecord.workerStatus.trim().toLowerCase() === workerStatus.trim().toLowerCase();
      });
    }

    if (sites === '0') {
      filteredData = filteredData.filter(data => data.records[0].isDeleted === 0);
    } else if (sites === '1') {
      filteredData = filteredData.filter(data => data.records[0].isDeleted === 1);
    }

    // Add index and update state
    setWorkOrderSummaryWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 }))
    .sort((a, b) => {
    const dateA = new Date(a?.initialVisit);
    const dateB = new Date(b?.initialVisit);
    return dateB.getTime() - dateA.getTime(); // Descending order
    }));
  }, [ workerStatus, filters, callRecords, sites]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const tableColumns = [
    {
      text: '#',
      // dataField: 'fmgVisitId',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (currentPage - 1) * sizePerPage + rowIndex + 1; // Adjust row number based on current page
        return rowNumber;
      },
      csvExport: false,
      sortable: false,
      style: { width: '50px' },
    },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const workOrderA = BigInt(a.replace(/\D/g, ''));
        const workOrderB = BigInt(b.replace(/\D/g, ''));

        if (order === 'asc') {
          return workOrderA > workOrderB ? 1 : -1;
        }
        if (order === 'desc') {
          return workOrderA < workOrderB ? 1 : -1;
        }
      },
      formatter: (cell, row) => {
        return (
          <Link to={`/worksite/work-order/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
    },
    {
      dataField: 'totalWorkHours',
      text: 'Total Working Hours',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell) => {
      //   if (typeof cell !== 'string' || !cell.includes('.')) {
      //     return '';
      //   }
      //   const [hours, minutes] = cell.split('.');
      //   return `${hours}Hr ${minutes}Min`;
      // },
      sort: true,
      sortFunc: (a, b, order) => {
        const aValue = parseFloat(a);
        const bValue = parseFloat(b);
      
        // Handle empty or NaN values and push them to the bottom
        if (isNaN(aValue)) return 1; // `a` is empty, place it at the bottom
        if (isNaN(bValue)) return -1; // `b` is empty, place it at the bottom
      
        if (order === 'asc') {
          return aValue - bValue; // Ascending order
        }
        
        return bValue - aValue; // Descending order
      },
    },
    {
      dataField: 'initialVisit',
      text: 'Initial Visit Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
      csvFormatter: (cell) => {
        if (cell) {
          // CSV format (same format as display)
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // For AM/PM format
          });
        } else {
          return '';
        }
      }
    },
    {
      dataField: 'latestVisit',
      text: 'Latest Visit Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
      csvFormatter: (cell) => {
        if (cell) {
          // CSV format (same format as display)
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // For AM/PM format
          });
        } else {
          return '';
        }
      }
    },
    // {
    //   dataField: 'records',
    //   text: 'Status',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sortFunc: (a, b, order) => {
    //     // Check if a and b are arrays and get the workerStatus of the last element
    //     const aStatus = Array.isArray(a) && a.length > 0 ? a[a.length - 1].workerStatus : null;
    //     const bStatus = Array.isArray(b) && b.length > 0 ? b[b.length - 1].workerStatus : null;
      
    //     // Handle empty/null values: always sort them last
    //     if (aStatus === null) return 1; // Move `a` to the last if it's empty
    //     if (bStatus === null) return -1; // Move `b` to the last if it's empty
      
    //     const aStr = String(aStatus);
    //     const bStr = String(bStatus);
      
    //     // Split the strings by spaces and compare word by word
    //     const aWords = aStr.split(' ');
    //     const bWords = bStr.split(' ');
      
    //     for (let i = 0; i < Math.max(aWords.length, bWords.length); i++) {
    //       const wordA = aWords[i] || ''; // Handle case if one array is shorter
    //       const wordB = bWords[i] || '';
      
    //       const compareResult = wordA.localeCompare(wordB);
    //       if (compareResult !== 0) {
    //         // If words are not equal, return the comparison result
    //         return order === 'asc' ? compareResult : -compareResult;
    //       }
    //     }
    //     // If all words are the same, consider the strings equal
    //     return 0;
    //   },      
      
    //   formatter: (records) => {
    //     if (Array.isArray(records) && records.length > 0) {
    //       const lastRecord = records[records.length - 1]; // Access the last record
    //       const cell = lastRecord.workerStatus;

    //       if (typeof cell === 'string') {
    //         const statusClassMap = {
    //           'Completed': 'badge bg-success text-white fw-lighter p-2',
    //           'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
    //           'Requires Part': 'badge bg-secondary fw-lighter p-2',
    //           'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
    //           'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
    //         };

    //         const capitalizedStatus = cell
    //           .split(' ')
    //           .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //           .join(' ');

    //         const className = statusClassMap[capitalizedStatus] || 'badge bg-dark';
    //         return <span className={className}>{capitalizedStatus}</span>;
    //       }
    //       return cell;
    //     }
    //     return null;
    //   },
    //   sort: true,
    //   // sortFunc: (a, b, order) => {
    //   //   const statusA = a || ''; // Handle empty values
    //   //   const statusB = b || ''; // Handle empty values
  
    //   //   if (statusA === '' || statusB === '') {
    //   //     return statusA === '' ? 1 : -1; // Keep empty values at the bottom
    //   //   }
  
    //   //   // For A-Z or Z-A sorting based on the 'order'
    //   //   return order === 'asc' ? statusA.localeCompare(statusB) : statusB.localeCompare(statusA);
    //   // },
    // },
    {
      dataField: 'records',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        // Check if a and b are arrays and get the workerStatus of the last element
        const aStatus = Array.isArray(a) && a.length > 0 ? a[a.length - 1].workerStatus : null;
        const bStatus = Array.isArray(b) && b.length > 0 ? b[b.length - 1].workerStatus : null;

        // Handle empty/null values: always sort them last
        if (aStatus === null) return 1; // Move `a` to the last if it's empty
        if (bStatus === null) return -1; // Move `b` to the last if it's empty

        const aStr = String(aStatus);
        const bStr = String(bStatus);

        // Split the strings by spaces and compare word by word
        const aWords = aStr.split(' ');
        const bWords = bStr.split(' ');

        for (let i = 0; i < Math.max(aWords.length, bWords.length); i++) {
          const wordA = aWords[i] || ''; // Handle case if one array is shorter
          const wordB = bWords[i] || '';

          const compareResult = wordA.localeCompare(wordB);
          if (compareResult !== 0) {
            // If words are not equal, return the comparison result
            return order === 'asc' ? compareResult : -compareResult;
          }
        }
        // If all words are the same, consider the strings equal
        return 0;
      },

      formatter: (records) => {
        if (Array.isArray(records) && records.length > 0) {
          const lastRecord = records[records.length - 1]; // Access the last record
          const cell = lastRecord.workerStatus;

          if (typeof cell === 'string') {
            const statusClassMap = {
              'Completed': 'badge bg-success text-white fw-lighter p-2',
              'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
              'Requires Part': 'badge bg-secondary fw-lighter p-2',
              'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
              'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
            };

            const capitalizedStatus = cell
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');

            const className = statusClassMap[capitalizedStatus] || 'badge bg-dark';
            return <span className={className}>{capitalizedStatus}</span>;
          }
          return cell;
        }
        return null;
      },
      csvFormatter: (records) => {
        if (Array.isArray(records) && records.length > 0) {
          const lastRecord = records[records.length - 1]; // Access the last record
          return lastRecord.workerStatus || ''; // Export the status as plain text
        }
        return '';
      },
      // sort: true,
      // sortFunc: (a, b, order) => {
      //   if (order === 'asc') {
      //     return Number(a) - Number(b);
      //   }
      //   return Number(b) - Number(a);
      // },
    },
  ];

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  // const options = {
  //   sizePerPageRenderer
  // };

  const options = {
    page: currentPage, // Ensure currentPage is used
    sizePerPage: sizePerPage, // Set the current size per page
    onSizePerPageChange: (sizePerPage, page) => {
      setSizePerPage(sizePerPage);
      setCurrentPage(page); // Update the page when size changes
    },
    onPageChange: (page) => setCurrentPage(page),
    sizePerPageRenderer,
  };

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mt-md-0 mb-4">
                  <h3 className="text-primary no-margin text-uppercase">Work Order Summary</h3>
                  <div  className="mt-2 mt-md-0">
                    {/* {csvPropsRef?.current && (
                      <ExportCSVButton   {...csvPropsRef?.current}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Work Order CSV</ExportCSVButton>
                    )} */}
                    <ExportCSVButton
                        {...csvPropsRef?.current}
                        disabled={!WorkOrderSummaryWithIndex?.length}
                        className={WorkOrderSummaryWithIndex?.length ? "btn-enabled" : "btn-disabled"}
                      >
                        <i className="pg-icon m-r-10"><FiShare size={'15'} /></i>
                        Export Work Order CSV
                      </ExportCSVButton>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-start'>
                  {/* <div className="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 px-1 p-l-0"> */}
                    <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                      <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                      <input type="text" className="form-control p-l-10" placeholder="Search by Work Order No" name='workOrderNumber' value={filters.workOrderNumber} onChange={handleSearchChange} />
                    </div>
                  {/* </div> */}
                  {/* <div className="col-12 col-md-6 col-lg-2 mb-2 mb-lg-0 px-1"> */}
                    <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                      <GroupSelect
                        placeholder='Status'
                        inputId='worksiteId'
                        value={workerStatus}
                        options={workerStatusFilterOptions}
                        onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                      />
                    </div>
                  {/* </div> */}

                  <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                    <div className="input-group ">
                      <GroupSelect
                        placeholder='All Sites'
                        inputId='worksiteId'
                        value={sites}
                        options={siteFilterOptions}
                        onChange={(selectedOption) => setSites(selectedOption?.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {WorkOrderSummaryWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={WorkOrderSummaryWithIndex}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'Work Order Summary.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default SummaryVisits;
