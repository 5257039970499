import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import Button from '../../UIElements/Button/UtilsButton';
import PageContainer from '../../UIElements/Containers';
import SlideUpModal from '../../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from "../../Forms/Elements/GroupSelect";

import { deleteCallRecord, postCallRecord, editCallRecord, getCallRecords, resetCallRecordsErrorData, resetDeleteCallRecordData, resetDeleteCallRecordErrorData, resetEditCallRecordData, resetEditCallRecordErrorData, getCallRecordingUrl, resetPostCallRecordData, resetPostCallRecordErrorData, getRemovedCallRecords } from '../../../api-actions/adminDataActions';
import { useDispatch, useSelector } from 'react-redux';

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getAllWorkSites, resetWorkSitesErrorData } from '../../../api-actions/workSiteDataAction';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiShare } from 'react-icons/fi';
import { RiShareBoxFill } from 'react-icons/ri';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import { Link } from 'react-router-dom';


const DeletedVisits = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const callRecordEmptyState = {
    worksiteId: '',
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const newCallRecordEmptyState = {
    worksiteId: '',
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };

  const [workerStatus, setWorkerStatus] = useState('');
  const workerTypeOptions = [{ value: 'CheckIn', label: 'CheckIn' }, { value: 'CheckOut', label: 'CheckOut' }]
  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'completed', label: 'Completed' }, { value: 'requires authorization', label: 'Requires Authorization' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires part', label: 'Requires Part' }]
  const workerStatusOptions = [{ value: 'additional work found', label: 'Additional Work Found' }, { value: 'completed', label: 'Completed' }, { value: 'requires authorization', label: 'Requires Authorization' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires part', label: 'Requires Part' }]

  let endDate = new Date(); // Current date
  let startDate = new Date(); // Create a new date object
  startDate.setMonth(startDate.getMonth() - 6);

  const dispatch = useDispatch();
  const workSitesData = useSelector((state) => state.WorkSiteData.workSitesData);
  const workSitesErrorData = useSelector((state) => state.WorkSiteData.getWorkSiteRecordsError);
  const postCallRecordData = useSelector((state) => state.AdminData.postCallRecordData);
  const postCallRecordError = useSelector((state) => state.AdminData.postCallRecordError);
  const callRecords = useSelector((state) => state.AdminData.removedCallRecords);
  const callRecordsErrorData = useSelector((state) => state.AdminData.getRemovedCallRecordsError);
  const editCallRecordData = useSelector((state) => state.AdminData.editCallRecordData);
  const editCallRecordError = useSelector((state) => state.AdminData.editCallRecordError);
  const deleteCallRecordData = useSelector((state) => state.AdminData.deleteCallRecordData);
  const deleteCallRecordError = useSelector((state) => state.AdminData.deleteCallRecordError);

  const [manualInput, setManualInput] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);

  const [filters, setFilters] = useState({
    searchValue: '',
    workOrderNumber: '',
    phoneNumber: '',
    workerName: '',
    workerStatus: '',
    teachnicianPhoneNumber: '',
    storeNumber: '',
    state: '',
    corporate: '',
    workOrderNumber: ''
  });

  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true)
  const [startDateRange, setStartDateRange] = useState(startDate);
  const [endDateRange, setEndDateRange] = useState(endDate);
  const [currentCallRecordId, setCurrentCallRecordId] = useState(null);
  const [callRecord, setCallRecord] = useState(callRecordEmptyState)
  const [newCallRecord, setNewCallRecord] = useState(newCallRecordEmptyState);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [worksiteId, setWorksiteId] = useState('');
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [exportCallRecordsData, setExportCallRecordsData] = useState()

  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  const options = {
    page: currentPage, // Ensure currentPage is used
    sizePerPage: sizePerPage, // Set the current size per page
    onSizePerPageChange: (sizePerPage, page) => {
      setSizePerPage(sizePerPage);
      setCurrentPage(page); // Update the page when size changes
    },
    onPageChange: (page) => setCurrentPage(page),
    sizePerPageRenderer,
  };

  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  useEffect(() => {
    dispatch(getRemovedCallRecords(jwtToken));
    dispatch(getAllWorkSites(jwtToken, 0));
  }, []);

  useEffect(() => {
    if (postCallRecordData && Object.keys(postCallRecordData)?.length > 0) {
      toast.success('Visit record added successfully');
      setSlideUpVisibleForCreate(false);
      setNewCallRecord(newCallRecordEmptyState)
      dispatch(getRemovedCallRecords(jwtToken));
      dispatch(resetPostCallRecordData());
    }
  }, [postCallRecordData]);

  useEffect(() => {
    if (postCallRecordError && Object.keys(postCallRecordError)?.length > 0) {
      toast.error(postCallRecordError.message);
      dispatch(resetPostCallRecordErrorData());
    }
  }, [postCallRecordError]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    });
    const data = callRecords.map((record, i) => ({ ...record, index: i + 1 }));
    setCallRecordsWithIndex(data);
  }, [callRecords]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetCallRecordsErrorData());
    }
  }, [callRecordsErrorData]);

  useEffect(() => {
    if (workSitesData) {
      setWorksiteOptions([]);
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesData?.map((record) => ({
        value: record?.fmgWorksiteId,
        label: record?.storeName,
      }));

      setWorksiteOptions(Array.from(new Map(worksiteData.map(item => [item.value, item])).values()))

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }

  }, [workSitesData]);

  useEffect(() => {
    if (workSitesErrorData && Object.keys(workSitesErrorData)?.length > 0) {
      toast.error(workSitesErrorData.message);
      dispatch(resetWorkSitesErrorData());
    }
  }, [workSitesErrorData]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const { workOrderNumber, phoneNumber, workerName, teachnicianPhoneNumber, storeNumber, state, corporate } = filters; // Extract phoneNumber and workerName filters

    setTimeout(() => {
      setIsLoading(false)
    });

    let filteredData = callRecords;

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.fmgWorksiteId === worksiteId);
    }

    if (startDateRange && endDateRange) {
      filteredData = filteredData.filter(data => {
        const checkInDate = new Date(data?.checkIn);
        const adjustedEndDateRange = new Date(endDateRange);
        adjustedEndDateRange.setHours(23, 59, 59, 999);
        return checkInDate >= startDateRange && checkInDate <= adjustedEndDateRange;
      });
    }

    if (workOrderNumber) {
      filteredData = filteredData.filter(data =>
        data.workOrderNumber?.toLowerCase().includes(workOrderNumber.toLowerCase())
      );
    }

    if (workOrderNumber) {
      filteredData = filteredData.filter(data =>
        data?.workOrderNumber?.toLowerCase().includes(workOrderNumber.toLowerCase())
      );
    }

    if (workOrderNumber) {
      filteredData = filteredData.filter(data =>
        data?.workOrderNumber?.toLowerCase().includes(workOrderNumber.toLowerCase())
      );
    }

    if (storeNumber) {
      filteredData = filteredData.filter(data =>
        data?.worksite?.storeNumber?.toLowerCase().includes(storeNumber.toLowerCase())
      );
    }

    if (corporate) {
      filteredData = filteredData.filter(data =>
        data?.corporate?.corporateName?.toLowerCase().includes(corporate.toLowerCase())
      );
    }

    if (state) {
      filteredData = filteredData.filter(data =>
        data?.worksite?.state?.toLowerCase().includes(state.toLowerCase())
      );
    }

    // Apply phoneNumber filter if provided
    if (teachnicianPhoneNumber) {
      filteredData = filteredData.filter(data =>
        data.workerDob?.toLowerCase().includes(teachnicianPhoneNumber.toLowerCase())

      );
    }

    // Apply workerName filter if provided
    if (workerName) {
      filteredData = filteredData.filter(data =>
        data.workerName?.toLowerCase().includes(workerName.toLowerCase())
      );
    }

    if (workerStatus && workerStatus !== 'all') {
      filteredData = filteredData.filter(data => data.workerStatus === workerStatus);
    }


    // Add index and update state
    setCallRecordsWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 }))
      .sort((a, b) => {
        const dateA = new Date(a?.checkIn);
        const dateB = new Date(b?.checkIn);
        return dateB.getTime() - dateA.getTime(); // Descending order
      }));
  }, [filters, callRecords, worksiteId, workerStatus, startDateRange, endDateRange]);


  useEffect(() => {
    if (startDateRange && endDateRange) {
      setManualInput(`${moment(startDateRange).format("MM/DD/YYYY")} - ${moment(endDateRange).format("MM/DD/YYYY")}`);
    }
  }, [startDateRange, endDateRange]);


  const handleDateRange = (event, picker) => {
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());

  };

  const handleManualChange = (e) => {
    const value = e.target.value;
    setManualInput(value); // Update the manual input field

    // Check if input format is valid and then update date range
    const dates = value.split(" - ");
    if (dates.length === 2) {
      const start = moment(dates[0], "MM/DD/YYYY", true);
      const end = moment(dates[1], "MM/DD/YYYY", true);

      if (start.isValid() && end.isValid()) {
        setStartDateRange(start);
        setEndDateRange(end);
      }
    }
  };

  useEffect(() => {
    if (editCallRecordData && Object.keys(editCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Visit record updated successfully');
      setSlideUpVisible(false);
      dispatch(getRemovedCallRecords(jwtToken));
      dispatch(resetEditCallRecordData());
    }
  }, [editCallRecordData]);

  useEffect(() => {
    if (editCallRecordError && Object.keys(editCallRecordError)?.length > 0) {
      toast.error(editCallRecordError.message);
      dispatch(resetEditCallRecordErrorData());
    }
  }, [editCallRecordError]);

  useEffect(() => {
    if (deleteCallRecordData && Object.keys(deleteCallRecordData)?.length > 0) {
      toast.success('Visit record data deleted successfully');
      dispatch(getRemovedCallRecords(jwtToken));
      dispatch(resetDeleteCallRecordData());
    }
  }, [deleteCallRecordData]);

  useEffect(() => {
    if (deleteCallRecordError && Object.keys(deleteCallRecordError)?.length > 0) {
      toast.error(deleteCallRecordError.message);
      dispatch(resetDeleteCallRecordErrorData());
    }
  }, [deleteCallRecordError]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  // Create call records modal
  const [slideUpVisibleForCreate, setSlideUpVisibleForCreate] = useState(false);

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [e.name]: selectOption
      }));
    } else {
      const { name, value } = e.target;
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [name]: value
      }));
    }
  };

  const newCallRecordInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setNewCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [e.name]: selectOption,
      }));
    } else {
      const { name, value } = e.target;
      setNewCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [name]: value,
      }));
    }
  };

  const handleEditClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisible(true)
    setCallRecord({
      worksiteId: row?.worksiteId,
      workOrderNumber: row.workOrderNumber,
      phoneNumber: row.phoneNumber,
      workerType: row.workerType,
      workerName: row.workerName,
      workerDob: row.workerDob,
      workerStatus: row.workerStatus,
      recordingUrl: row.recordingUrl,
      checkIn: row?.checkIn ? new Date(row?.checkIn) : '',
      checkOut: row?.checkOut ? new Date(row?.checkOut) : '',
    })
  }

  const handleEdit = () => {
    const missingFields = [];
    // if (!callRecord.phoneNumber) missingFields.push('Phone number');
    if (!callRecord.workerName) missingFields.push('Worker name');
    if (!callRecord.workerDob) missingFields.push('Technician phone no');
    if (!callRecord.workOrderNumber) missingFields.push('Work order number');
    if (!callRecord.checkIn) missingFields.push('Check in');
    // if (!callRecord.workerStatus) missingFields.push('Worker status');
    // if (!callRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(editCallRecord(jwtToken, currentCallRecordId, callRecord));
    }
  };

  const handleDeleteClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleDelete = () => {
    if (currentCallRecordId) {
      dispatch(deleteCallRecord(jwtToken, currentCallRecordId))
      setSlideUpVisibleForDelete(false)
    }
  }

  const handleClick = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.recordingUrl);
      setName(row.workerName);
    }
  };

  const closeModal = () => {
    setName('');
    setAudioUrl('');
    setAudioSlideUpVisible(false);
  };

  const handleAddCallRecords = async () => {
    setSlideUpVisibleForCreate(true)
  }

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true)
  };

  const handleCreate = () => {
    const missingFields = [];
    if (!newCallRecord.worksiteId) missingFields.push('Worksite');
    // if (!newCallRecord.phoneNumber) missingFields.push('Phone number');
    if (!newCallRecord.workerName) missingFields.push('Worker name');
    if (!newCallRecord.workerDob) missingFields.push('Technician phone no');
    // if (!newCallRecord.workerStatus) missingFields.push('Worker status');
    if (!newCallRecord.workOrderNumber) missingFields.push('Work order number');
    if (!newCallRecord.checkIn) missingFields.push('Check in');
    // if (!newCallRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      const missingFieldsString = missingFields.join(', ');
      toast.error(`The following fields are required: ${missingFieldsString}`);
      return;
    }

    if (missingFields?.length === 0) {
      dispatch(postCallRecord(jwtToken, newCallRecord));
    }

  }

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  useEffect(() => {
    setExportCallRecordsData(nullToEmpty(callRecordsWithIndex))
  }, [callRecordsWithIndex])

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };

    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'checkIn' || key === 'checkOut') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const handleClickUrlOne = (row) => {
    if (!audioSlideUpVisible && row.checkInRecordingUrl) {
      setRecordingUrl(row.checkInRecordingUrl);
      setName(row.workerName);
    }
  };

  const handleClickUrlTwo = (row) => {
    if (!audioSlideUpVisible && row.checkOutRecordingUrl) {
      setRecordingUrl(row.checkOutRecordingUrl);
      setName(row.workerName);
    }
  };

  const tableColumns = [
    {
      dataField: 'fmgVisitId',
      text: 'FMG VISIT ID',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      // formatter: (cell, row, rowIndex) => {
      //   const rowNumber = (currentPage - 1) * sizePerPage + rowIndex + 1; // Adjust row number based on current page
      //   return rowNumber;
      // },
      csvExport: true,
      sort: true,
      style: { width: '50px' },
    },
    {
      dataField: 'corporate.corporateName',
      text: 'corporate',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        return (
          <Link to={`/admin/work-order/${row.fmgWorksiteId}/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'worksite.storeNumber',
      text: 'Store No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'worksite.storeName',
      text: 'Store Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'worksite.state',
      text: 'State',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'checkIn',
      text: 'Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'checkIn',
      text: 'Check In Time',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a);
        const dateB = new Date(b);

        if (order === 'desc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginTop: '7px',
                }}
                onClick={() => handleClickUrlOne(row)}
              >
                <div>
                  {row?.checkInRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>
                  {row?.checkInCallDuration
                    ? `${row?.checkInCallDuration} sec`
                    : ''}
                </div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'checkOut',
      text: 'Check Out Time',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (!a) return 1;
        if (!b) return -1;

        if (order === 'asc') {
          return a.localeCompare(b);
        }
        return b.localeCompare(a);
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginTop: '7px',
                }}
                onClick={() => handleClickUrlTwo(row)}
              >
                <div>
                  {row?.checkOutRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>
                  {row?.checkOutCallDuration
                    ? `${row?.checkOutCallDuration} sec`
                    : ''}
                </div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'worksite.timeZone',
      text: 'Time Zone',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'workHours',
      text: 'Hours Worked',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const aValue = parseFloat(a);
        const bValue = parseFloat(b);

        // Handle empty or NaN values and push them to the bottom
        if (isNaN(aValue)) return 1; // `a` is empty, place it at the bottom
        if (isNaN(bValue)) return -1; // `b` is empty, place it at the bottom

        if (order === 'asc') {
          return aValue - bValue; // Ascending order
        }

        return bValue - aValue; // Descending order
      },
      formatter: (cell) => {
        if (typeof cell !== 'string' || !cell.includes('.')) {
          return '';
        }
        const [hours, minutes] = cell.split('.');
        return `${hours}Hr ${minutes}Min`;
      },
      csvFormatter: (cell) => {
        if (cell) {
          if (typeof cell !== 'string' || !cell.includes('.')) {
            return '';
          }
          const [hours, minutes] = cell.split('.');
          return `${hours}Hr ${minutes}Min`;
        } else {
          return ''
        }
      },
    },
    {
      dataField: 'workerStatus',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell === 'string') {
          // Define the status to className map
          const statusClassMap = {
            'Completed': 'badge bg-success text-white fw-lighter p-2',
            'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
            'Requires Part': 'badge bg-secondary fw-lighter p-2',
            'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
            'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
          };

          // Get the capitalized status
          const capitalizedStatus = cell
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          // Determine the appropriate className based on the status
          const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
          return <span className={className}>{capitalizedStatus}</span>;
        }
        return cell;
      },
      csvFormatter: (cell) => cell || '',
      sort: true,
      sortFunc: (a, b, order) => {
        if (!a) return 1;
        if (!b) return -1;

        if (order === 'asc') {
          return a.localeCompare(b);
        }
        return b.localeCompare(a);
      },
    },
    {
      dataField: 'phoneNumber',
      text: 'Store Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'workerName',
      text: 'Technician Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'workerDob',
      text: 'Technician Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvFormatter: (cell) => cell || '',
      sort: true,
    },
    // {
    //   dataField: 'workerType',
    //   text: 'Type',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },

    // {
    //   dataField: 'callDuration',
    //   text: 'Call Duration',
    //   formatter: (cell, row) => {
    //     return (
    //       <div style={{ display: 'flex', alignItems: 'center', gap: "5px", justifyContent: 'center', cursor: 'pointer' }} onClick={() => handleClick(row)}>
    //         <div>
    //           {(cell && row?.recordingUrl) && (
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="#007bff"
    //               className="bi bi-play-circle"
    //               viewBox="0 0 16 16"
    //               style={{ marginRight: '5px' }}
    //             >
    //               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    //               <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
    //             </svg>
    //           )}
    //         </div>
    //         <div>{cell ? `${cell} sec` : ''}</div>
    //       </div>
    //     );
    //   },
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    // {
    //   dataField: 'createdAt',
    //   text: 'Created At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell) => moment(cell).format("MM/DD/YYYY h:mm A"),
    // },
    {
      dataField: 'manualEntry',
      text: 'Manual Entry',
      csvExport: false,
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (!a || !a.role) return 1;
        if (!b || !b.role) return -1;

        const aValue = `${a.role}\0${a.editedBy?.name || ''}`.toLowerCase();
        const bValue = `${b.role}\0${b.editedBy?.name || ''}`.toLowerCase();

        if (order === 'asc') {
          return aValue.localeCompare(bValue);
        }
        return bValue.localeCompare(aValue);
      },
      csvExport: true,
      formatter: (cell) => {
        if (!cell) return '';
        if (!cell?.role) return '-';

        return (
          <>
            {`${cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase()}`}
            {cell.editedBy?.name ? ` - ${cell.editedBy.name}` : ''}
            <br />
            {cell.editedAt ? `(${formateDate(cell.editedAt)})` : ''}
          </>
        );
      },
      csvFormatter: (cell) => {
        if (!cell) return '';
        if (!cell.role) return '-';
      
        const roleFormatted = cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase();
        const editedByName = cell.editedBy?.name ? ` ${cell.editedBy.name}` : ''; // Include name if it exists
        const formattedDate = cell.editedAt ? ` (${formateDate(cell.editedAt)})` : ''; // Include date if it exists
      
        return `${roleFormatted}-${editedByName}${formattedDate}`;
      }
    },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green', color: 'white' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon"><RiShareBoxFill color='white' /></i>
          </Button>
          {/* <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i> */}
            {/* <span className="">Edit</span> */}
          {/* </Button> */}
          {/* <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i> */}
            {/* Delete */}
          {/* </Button> */}
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mb-3">
                  <h3 className="text-primary no-margin text-uppercase">Removed Visits</h3>
                  <div className="mt-2 mt-md-0">
                    {/* {csvPropsRef?.current && callRecordsWithIndex?.length > 0 && (
                      <ExportCSVButton {...csvPropsRef?.current}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Visits CSV</ExportCSVButton>
                    )} */}
                    <ExportCSVButton
                      {...csvPropsRef?.current}
                      disabled={!callRecordsWithIndex?.length}
                      className={callRecordsWithIndex?.length ? "btn-enabled" : "btn-disabled"}
                    >
                      <i className="pg-icon m-r-10"><FiShare size={'15'} /></i>
                      Export Removed Visits CSV
                    </ExportCSVButton>
                  </div>
                </div>

                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT btn-group d-flex align-items-center">
                      {/* <button aria-label="" className="btn btn-primary btn-lg m-r-10" type="button" onClick={handleAddCallRecords}>
                        + Add Call Records
                      </button> */}

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body mt-2">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block mt-2 mt-md-0" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Edit Visit Record</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body px-4 px-md-5 py-4">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    <div className='row'>
                                      <div className='col-md-6'>
                                        <label for='phoneNumber'>Store Phone Number</label>
                                        <input type="text" className="form-control w-100" placeholder='Update Phone Number' value={callRecord.phoneNumber} onChange={handleInputChange} name='phoneNumber' id='phoneNumber' />
                                      </div>

                                      <div className='col-md-6'>
                                        <label for='workOrderNumber' className='mt-3 mt-md-0'>Work Order Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Update Work Order Number' value={callRecord.workOrderNumber} onChange={handleInputChange} name='workOrderNumber' id='workOrderNumber' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='workerName' className='mt-3'>Technician Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Update Worker Name' value={callRecord.workerName} onChange={handleInputChange} name='workerName' id='workerName' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='workerDob' className='mt-3'>Technician Phone No<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Update Technician Phone No' value={callRecord.workerDob} onChange={handleInputChange} name='workerDob' id='workerDob' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label htmlFor='workerStatus' className='mt-3'>Worker Status</label>
                                        <GroupSelect
                                          inputId='workerStatus'
                                          value={callRecord.workerStatus}
                                          options={workerStatusOptions}
                                          onChange={(selectedOption) => handleInputChange({ name: 'workerStatus' }, selectedOption.value)}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label for='recordingUrl' className='mt-3'>Recording URL</label>
                                        <input type="text" className="form-control w-100" placeholder='Update Recording URL' value={callRecord.recordingUrl} onChange={handleInputChange} name='recordingUrl' id='recordingUrl' />
                                      </div>
                                    </div>

                                    <label for='checkIn' className='mt-3'>Check In<span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                      onChange={(selectedOption) => handleInputChange({ name: 'checkIn' }, selectedOption)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      placeholderText="Select date and time"
                                      className="form-control w-100"
                                      selected={callRecord.checkIn}
                                      name='checkIn'
                                      id='checkIn'
                                    />

                                    <label for='checkOut' className='mt-3'>Check Out</label>
                                    <DatePicker
                                      onChange={(selectedOption) => handleInputChange({ name: 'checkOut' }, selectedOption)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      placeholderText="Select date and time"
                                      className="form-control w-100"
                                      selected={callRecord.checkOut}
                                      name='checkOut'
                                      id='checkOut'
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary mt-3" onClick={handleEdit}>
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisibleForCreate ?
                        <SlideUpModal visible={slideUpVisibleForCreate} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForCreate(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Add Visit Record</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForCreate(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body px-4 px-md-5 py-4">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label htmlFor='worksiteId'>Worksite<span style={{ color: 'red' }}>*</span></label>
                                        <GroupSelect
                                          inputId='worksiteId'
                                          value={newCallRecord.worksiteId}
                                          options={worksiteOptions}
                                          onChange={(selectedOption) => newCallRecordInputChange({ name: 'worksiteId' }, selectedOption.value)}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label for='phoneNumber'>Phone Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Phone Number' value={newCallRecord.phoneNumber} onChange={newCallRecordInputChange} name='phoneNumber' id='phoneNumber' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='workOrderNumber' className='mt-3'>Work Order Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Work Order Number' value={newCallRecord.workOrderNumber} onChange={newCallRecordInputChange} name='workOrderNumber' id='workOrderNumber' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='workerName' className='mt-3'>Technician Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Worker Name' value={newCallRecord.workerName} onChange={newCallRecordInputChange} name='workerName' id='workerName' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='workerDob' className='mt-3'>Technician Phone No<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Technician Phone No' value={newCallRecord.workerDob} onChange={newCallRecordInputChange} name='workerDob' id='workerDob' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='callDuration' className='mt-3'>Call Duration<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder='Call Duration' value={newCallRecord.callDuration} onChange={newCallRecordInputChange} name='callDuration' id='callDuration' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label htmlFor='workerStatus' className='mt-3'>Worker Status<span style={{ color: 'red' }}>*</span></label>
                                        <GroupSelect
                                          inputId='workerStatus'
                                          value={newCallRecord.workerStatus}
                                          options={workerStatusOptions}
                                          onChange={(selectedOption) => newCallRecordInputChange({ name: 'workerStatus' }, selectedOption.value)}
                                        />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='recordingUrl' className='mt-3'>Recording URL</label>
                                        <input type="text" className="form-control w-100" placeholder='Recording URL' value={newCallRecord.recordingUrl} onChange={newCallRecordInputChange} name='recordingUrl' id='recordingUrl' />
                                      </div>
                                    </div>

                                    <label for='checkIn' className='mt-3'>Check In<span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                      onChange={(selectedOption) => newCallRecordInputChange({ name: 'checkIn' }, selectedOption)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      placeholderText="Select date and time"
                                      className="form-control w-100"
                                      selected={newCallRecord.checkIn}
                                      name='checkIn'
                                      id='checkIn'
                                    />

                                    <label for='checkOut' className='mt-3'>Check Out</label>
                                    <DatePicker
                                      onChange={(selectedOption) => newCallRecordInputChange({ name: 'checkOut' }, selectedOption)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      placeholderText="Select date and time"
                                      className="form-control w-100"
                                      selected={newCallRecord.checkOut}
                                      name='checkOut'
                                      id='checkOut'
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary" onClick={handleCreate}>
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {audioSlideUpVisible ? (
                        <SlideUpModal visible={audioSlideUpVisible} width={'400'} effect="fadeInUp" onClickAway={() => closeModal()}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button
                                  aria-label=""
                                  type="button"
                                  className="close"
                                  onClick={() => closeModal()}
                                  aria-hidden="true"
                                >
                                  <i className="pg-icon">close</i>
                                </button>
                                <h4 className="text-primary no-margin text-uppercase text-center">{name}</h4>
                              </div>
                              {audioUrl?.length > 0 ? (
                                <div className="modal-body">
                                  <form role="form">
                                    <div className="form-group-attached">
                                      <div className="row justify-content-center">
                                        <audio controls style={{ margin: "0", height: '40px' }}>
                                          <source src={audioUrl} type="audio/mpeg" />
                                        </audio>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                <div className="modal-body">
                                  <div className="row justify-content-center">
                                    <div class="spinner-grow" role="status">
                                      <span class="visually-hidden">Loading...</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </SlideUpModal>
                      ) : null}

                      {slideUpVisibleForViewData ?
                        <SlideUpModal visible={slideUpVisibleForViewData} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Visit Record Details</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body py-3 h100vh">
                                <div className="row">
                                  <div className="col-md-6">
                                    {/* <p><strong>ID:</strong> {viewData.id}</p> */}

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Worksite</p>
                                          {viewData.worksite.storeName ?
                                            <p class="bold mb-0">{viewData.worksite.storeName}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Technician Name</p>
                                          {viewData.workerName ?
                                            <p class="bold mb-0">{viewData.workerName}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check In</p>
                                          {viewData.checkIn ?
                                            <p class="bold mb-0">{formateDate(viewData.checkIn)}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check In Call Duration</p>
                                          {viewData.checkInCallDuration ?
                                            <p class="bold mb-0">{`${viewData?.checkInCallDuration} sec` || 'N/A'}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Work Order Number</p>
                                          {viewData.workOrderNumber ?
                                            <p class="bold mb-0">{viewData.workOrderNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Created At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div className="pgn-message card-header">
                                        <div>
                                          <p>Manual Entry</p>
                                          {viewData?.manualEntry.role ?
                                            <p className="bold mb-0"><span className='text-capitalize'>{viewData?.manualEntry?.role}</span> - {viewData?.manualEntry?.editedBy?.name} <br /> ({formateDate(viewData?.manualEntry?.editedAt)})</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                  <div className="col-md-6">

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Store Phone Number</p>
                                          {viewData.phoneNumber ?
                                            <p class="bold mb-0">{viewData.phoneNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Technician Phone No</p>
                                          {viewData.workerDob ?
                                            <p class="bold mb-0">{viewData.workerDob}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check Out</p>
                                          {viewData.checkOut ?
                                            <p class="bold mb-0">{formateDate(viewData.checkOut)}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check Out Call Duration</p>
                                          {viewData?.checkOutCallDuration ?
                                            <p class="bold mb-0">{`${viewData?.checkOutCallDuration} sec` || 'N/A'}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Worker Status</p>
                                          {viewData.workerStatus ?
                                            <p class="bold mb-0">{viewData.workerStatus}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Updated At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.updatedAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* <p><strong>Check In Recording URL:</strong> {viewData?.checkInRecordingUrl || 'N/A'}</p> */}
                                    {/* <p><strong>Check Out Recording URL:</strong> {viewData?.checkOutRecordingUrl || 'N/A'}</p> */}
                                    {/* <p><strong>Check In Call SID:</strong> {viewData?.checkInCallSID || 'N/A'}</p>
                                    <p><strong>Check Out Call SID:</strong> {viewData?.checkOutCallSID || 'N/A'}</p>
                                    <p><strong>Check In Recording SID:</strong> {viewData?.checkInRecordingSID || 'N/A'}</p>
                                    <p><strong>Check Out Recording SID:</strong> {viewData?.checkOutRecordingSID || 'N/A'}</p> */}
                                    {/* <p><strong>Worksite:</strong> {viewData?.worksiteId}</p> */}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-start">
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input
                    type="text"
                    className="form-control p-l-10"
                    placeholder="Search by Worker Order No"
                    value={filters.workOrderNumber}
                    onChange={handleSearchChange}
                    name="workOrderNumber"
                  />
                </div>
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input
                    type="text"
                    className="form-control p-l-10"
                    placeholder="Search by Technician Phone No"
                    name='teachnicianPhoneNumber'
                    value={filters.teachnicianPhoneNumber}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <GroupSelect
                    placeholder='Status'
                    inputId='worksiteId'
                    value={workerStatus}
                    options={workerStatusFilterOptions}
                    onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                  />
                </div>
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <GroupSelect
                    inputId="worksiteId"
                    value={worksiteId}
                    options={worksiteOptionsForFilter}
                    onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-start">
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input
                    type="text"
                    className="form-control p-l-10"
                    placeholder="Search by Technician Name"
                    value={filters.workerName}
                    onChange={handleSearchChange}
                    name="workerName"
                  />
                </div>
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input
                    type="text"
                    className="form-control p-l-10"
                    placeholder="Search by Store No"
                    name='storeNumber'
                    value={filters.storeNumber}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input
                    type="text"
                    className="form-control p-l-10"
                    placeholder="Search by State"
                    name='state'
                    value={filters.state}
                    onChange={handleSearchChange}
                  />
                </div>
                {/* 
                <div className="input-group col-md-3 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input 
                    type="text" 
                    className="form-control p-l-10" 
                    placeholder="Search by corporate" 
                    name='corporate'
                    value={filters.corporate}
                    onChange={handleSearchChange} 
                  />
                </div> 
                */}
                <DateRangePicker
                  startDate={startDateRange}
                  onApply={handleDateRange} // Handle date picker change
                  focusedInput={focusedInput} // Track which input is focused
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)} // Handle focus change
                  endDate={endDateRange}
                  showTimeSelect
                >
                  <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                    <span className="input-group-text input-date-icon-pre">
                      <i className="pg-icon">calendar</i>
                    </span>
                    <input
                      type="text"
                      name="reservation"
                      id="daterangepicker"
                      className="form-control"
                      style={{ width: "250px", height: "35px" }}
                      value={manualInput}
                      onChange={handleManualChange} // Allow manual input change
                    />
                  </div>
                </DateRangePicker>
              </div>

              <div className="card-body custable">

                {!isLoading ? callRecordsWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCallRecordsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'Removed Visits.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      } return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: false,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                ) : <Loader />}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default DeletedVisits;
