import React from 'react';

const ButtonSpinner = () => {
    return (
        <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '0px' }}>
            <span class="spinner-border-upload"></span>
        </div>
    );
};

export default ButtonSpinner;
