import React from "react";
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretDown);

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon="caret-down" />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

let focusColor = "1px solid #007be8";

const GroupSelect = ({ placeholder, inputId, value, options, onChange }) => (
  <div className="w-100">
    <Select
      placeholder={placeholder}
      inputId={inputId}
      value={options.find(option => option.value === value)}
      options={options}
      onChange={(selectedOption) => onChange(selectedOption)}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          marginRight: '5px',
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: '2px',
          borderWidth: '1px',
          border: state.isFocused ? focusColor : 'default',
          "&:hover": {
            border: state.isFocused ? focusColor : 'default',
          },
          boxShadow: 'none',
          maxHeight: '35px',
          minHeight: '20px',
        }),
        groupHeading: (provided) => ({
          ...provided,
          color: '#212121',
          fontSize: '13px',
          fontWeight: 'bold',
          textTransform: 'none',
        }),
        option: (provided, state) => ({
          ...provided,
          marginLeft: '10px',
          width: '95%',
          color: 'default',
          cursor: 'pointer',
          "&:active": {
            backgroundColor: "rgba(33, 33, 33, 0.07)"
          },
          backgroundColor: state.isSelected ? "rgba(33, 33, 33, 0.07)" : "default",
          backgroundColor: state.isFocused ? "rgba(33, 33, 33, 0.07)" : "default",
          borderRadius: '3px'
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: '1px',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          zIndex: 9999,
        }),
      }}
    />
  </div>
);

export default GroupSelect;
