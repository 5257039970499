import React from "react";

const Loader = () => {
  return (
    <div className="FMloaderWrap">
      <div class="FMloader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      Loading
    </div>
  );
};

export default Loader;
