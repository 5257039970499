export const data = [
  {
    key: "6 month sale X",
    values: [
      "11/22/2014",
      "11/22/2014",
      "11/24/2014",
      "11/25/2014",
      "11/26/2014",
      "11/27/2014",
      "11/28/2014",
      "11/29/2014",
      "11/30/2014",
    ],
  },
  {
    key: "6 month sale Y",
    values: [0, 10, 20, 20, 30, 20, 10, 30, 15],
  },
  {
    key: "chart one X",
    values: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    key: "chart one Y",
    values: [0, 20, 28, 20, 15, 20, 15, 35],
  },
  {
    key: "chart two X",
    values: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    key: "chart two Y",
    values: [30, 0, 25, 29, 20, 22, 20, 42],
  },
];
