const AdminDataActions = {
  POST_CALL_RECORD_DATA: 'POST_CALL_RECORD_DATA',
  POST_CALL_RECORD_ERROR: 'POST_CALL_RECORD_ERROR',
  POST_PHONE_NUMBER_DATA: 'POST_PHONE_NUMBER_DATA',
  POST_PHONE_NUMBER_ERROR: 'POST_PHONE_NUMBER_ERROR',
  POST_WORK_ORDER_NUMBER_DATA: 'POST_WORK_ORDER_NUMBER_DATA',
  POST_WORK_ORDER_NUMBER_ERROR: 'POST_WORK_ORDER_NUMBER_ERROR',

  EDIT_WORK_SITE_DATA: 'EDIT_WORK_SITE_DATA',
  EDIT_WORK_SITE_ERROR: 'EDIT_WORK_SITE_ERROR',
  EDIT_CALL_RECORD_DATA: 'EDIT_CALL_RECORD_DATA',
  EDIT_CALL_RECORD_ERROR: 'EDIT_CALL_RECORD_ERROR',
  EDIT_PHONE_NUMBER_DATA: 'EDIT_PHONE_NUMBER_DATA',
  EDIT_PHONE_NUMBER_ERROR: 'EDIT_PHONE_NUMBER_ERROR',
  EDIT_WORK_ORDER_NUMBER_DATA: 'EDIT_WORK_ORDER_NUMBER_DATA',
  EDIT_WORK_ORDER_NUMBER_ERROR: 'EDIT_WORK_ORDER_NUMBER_ERROR',
  EDIT_ADMIN_PASSWORD_DATA: 'EDIT_ADMIN_PASSWORD_DATA',
  EDIT_ADMIN_PASSWORD_ERROR: 'EDIT_ADMIN_PASSWORD_ERROR',
  EDIT_ADMIN_DETAILS_DATA: 'EDIT_ADMIN_DETAILS_DATA',
  EDIT_ADMIN_DETAILS_ERROR: 'EDIT_ADMIN_DETAILS_ERROR',
  UPDATE_CHECK_IN_CALL_RECORD_DATA: 'UPDATE_CHECK_IN_CALL_RECORD_DATA',
  UPDATE_CHECK_IN_CALL_RECORD_ERROR: 'UPDATE_CHECK_IN_CALL_RECORD_ERROR',

  DELETE_WORK_SITE_DATA: 'DELETE_WORK_SITE_DATA',
  DELETE_WORK_SITE_ERROR: 'DELETE_WORK_SITE_ERROR',
  INACTIVE_WORK_SITE_DATA: 'INACTIVE_WORK_SITE_DATA',
  INACTIVE_WORK_SITE_ERROR: 'INACTIVE_WORK_SITE_ERROR',
  DELETE_CALL_RECORD_DATA: 'DELETE_CALL_RECORD_DATA',
  DELETE_CALL_RECORD_ERROR: 'DELETE_CALL_RECORD_ERROR',
  DELETE_PHONE_NUMBER_DATA: 'DELETE_PHONE_NUMBER_DATA',
  DELETE_PHONE_NUMBER_ERROR: 'DELETE_PHONE_NUMBER_ERROR',
  DELETE_WORK_ORDER_NUMBER_DATA: 'DELETE_WORK_ORDER_NUMBER_DATA',
  DELETE_WORK_ORDER_NUMBER_ERROR: 'DELETE_WORK_ORDER_NUMBER_ERROR',

  GET_CALL_RECORDS_DATA: 'GET_CALL_RECORDS_DATA',
  GET_CALL_RECORDS_ERROR: 'GET_CALL_RECORDS_ERROR',
  GET_RECENT_CALL_RECORDS_DATA: 'GET_RECENT_CALL_RECORDS_DATA',
  GET_RECENT_CALL_RECORDS_ERROR: 'GET_RECENT_CALL_RECORDS_ERROR',
  GET_REMOVED_CALL_RECORDS_DATA: 'GET_REMOVED_CALL_RECORDS_DATA',
  GET_REMOVED_CALL_RECORDS_ERROR: 'GET_REMOVED_CALL_RECORDS_ERROR',
  GET_ALL_PHONE_NUMBER_DATA: 'GET_ALL_PHONE_NUMBER_DATA',
  GET_ALL_PHONE_NUMBER_ERROR: 'GET_ALL_PHONE_NUMBER_ERROR',
  GET_ALL_WORK_ORDER_NUMBER_DATA: 'GET_ALL_WORK_ORDER_NUMBER_DATA',
  GET_ALL_WORK_ORDER_NUMBER_ERROR: 'GET_ALL_WORK_ORDER_NUMBER_ERROR',
  GET_ALL_LOG_RECORDS_DATA: 'GET_ALL_LOG_RECORDS_DATA',
  GET_ALL_LOG_RECORDS_ERROR: 'GET_ALL_LOG_RECORDS_ERROR',
  GET_ADMIN_DATA: 'GET_ADMIN_DATA',
  GET_ADMIN_ERROR: 'GET_ADMIN_ERROR',
  
  GET_COMPLETED_VISITS_DATA: 'GET_COMPLETED_VISITS_DATA',
  GET_COMPLETED_VISITS_ERROR: 'GET_COMPLETED_VISITS_ERROR',
  GET_CHECK_IN_VISITS_DATA: 'GET_CHECK_IN_VISITS_DATA',
  GET_CHECK_IN_VISITS_ERROR: 'GET_CHECK_IN_VISITS_ERROR',
  GET_WORK_ORDER_SUMMARY_DATA: 'GET_WORK_ORDER_SUMMARY_DATA',
  GET_WORK_ORDER_SUMMARY_ERROR: 'GET_WORK_ORDER_SUMMARY_ERROR',
  GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA: 'GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA',
  GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR: 'GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR',
  GET_WORK_SITE_MANAGER_DATA: 'GET_WORK_SITE_MANAGER_DATA',
  GET_WORK_SITE_MANAGER_ERROR: 'GET_WORK_SITE_MANAGER_ERROR',
};

export default AdminDataActions;
