
import WorkSiteDataActions from '../redux/actions/WorkSiteDataAction';
import apiAdminWithJwt from '../services/apiAdminWithJwt';

// GET Methods
export const getAllWorkSites = (token, isInActive, editData) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`admin/get-all-worksite?isInActive=${isInActive}&editData=${editData}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_ALL_WORK_SITES_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_ALL_WORK_SITES_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllInActiveWorkSites = (token, isInActive, editData) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`admin/get-all-worksite?isInActive=${isInActive}&editData=${editData}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_IN_ACTIVE_WORK_SITES_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_IN_ACTIVE_WORK_SITES_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorksiteWorkOrderNumbers = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/get-worksite-work-orders?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_NUMBERS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_NUMBERS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorksiteCallRecords = (token, id, state) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/get-worksite-call-records?worksiteId=${id}&state=${state}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_CALL_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_CALL_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getRemovedWorksiteCallRecords = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/get-deleted-worksite-call-records?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_REMOVED_CALL_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_REMOVED_CALL_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};


export const getWorksiteCheckinVisits = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/report/get-worksite-checkin-visits?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_CHECKIN_RECORDS,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_CHECKIN_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorksitePhoneNumbers = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/get-worksite-phone-numbers?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_PHONE_NUMBERS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_PHONE_NUMBERS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getSingleWorksite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`worksite/get-single-worksite-user?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_MANAGER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_MANAGER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorksiteTollFree = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`worksite/get-worksite-tollfree-number?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORKSITE_TOLL_FREE_NUMBER,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORKSITE_TOLL_FREE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorksiteCompletedVisits = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/reports/get-worksite-completed-visits?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_COMPLETED_VISITS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_COMPLETED_VISITS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorksiteWorkOrderSummary = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/worksite/reports/get-worksite-visits-summary?worksiteId=${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_SUMMARY_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_SUMMARY_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCallRecordingUrl = (token, recordingUrl) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
      return await apiWithToken
        .get(
          `worksite/get-recording-url?recordingUrl=${encodeURIComponent(
            recordingUrl
          )}`,
          { responseType: 'blob' }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error;
        });
  };
};

export const getCallRecordsByWorkOrderNumber = (token, worksiteId, workOrderNumber) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`worksite/get-call-records-by-work-order-number?worksiteId=${worksiteId}&workOrderNumber=${workOrderNumber}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// POST Methods
export const postWorkSiteByAdmin = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('admin/create-worksite', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkSiteByCorporate = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-worksite', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postCallRecordByWorksite = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('worksite/create-one-call-record', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postPhoneNumberByWorksite = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('worksite/create-phone-number', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkOrderNumberByWorksite = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('worksite/create-work-order-number', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postCallRecord = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-one-call-record', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postPhoneNumber = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-phone-number', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkOrderNumber = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-work-order-number', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkOrderNumberUsingCsv = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('worksite/create-work-order-number-using-csv', data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// EDIT Methods
export const editWorkSite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`worksite/edit-worksite/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editCallRecordByWorksite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`worksite/edit-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const updateCallRecordByWorksite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`worksite/report/update-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.UPDATE_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.UPDATE_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPhoneNumberByWorksite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`worksite/edit-phone-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editWorkOrderNumberByWorksite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`worksite/edit-work-order-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editCallRecord = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPhoneNumber = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-phone-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editWorkOrderNumber = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-work-order-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPassword = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`worksite-auth/password-reset/${id}`, data)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_SITE_PASSWORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.EDIT_WORK_SITE_PASSWORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// DELETE Methods
export const deleteCallRecord = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-call-record/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deletePhoneNumber = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-phone-number/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteWorkOrderNumber = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-work-order-number/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const resetEditCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetEditCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetUpdateCheckInCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.UPDATE_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetUpdateCheckInCallRecordError = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.UPDATE_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetEditPhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetEditPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetEditWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditPasswordData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_WORK_SITE_PASSWORD_DATA, payload: {} });
  };
};

export const resetEditPasswordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_WORK_SITE_PASSWORD_ERROR, payload: {} });
  };
};

// DELETE Methods
export const deleteWorkSite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`worksite/delete-worksite/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteCallRecordByWorksite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`worksite/delete-call-record/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deletePhoneNumberByWorksite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`worksite/delete-phone-number/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteWorkOrderNumberByWorksite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`worksite/delete-work-order-number/${id}`)
      .then((res) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const resetDeleteCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetDeleteCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetDeletePhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetDeletePhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetDeleteWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetDeleteWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

// POST Resets
export const resetPostWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_WORK_SITE_DATA, payload: {} });
  };
};

export const resetWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_WORK_SITE_ERROR, payload: {} });
  };
};

export const resetInActiveWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_IN_ACTIVE_WORK_SITES_ERROR, payload: {} });
  };
};

export const resetPostCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetPostCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetPostPhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetPostPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetPostWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetPostWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetPostWorkOrderNumberUsingCsvData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_DATA, payload: {} });
  };
};

export const resetPostWorkOrderNumberUsingCsvError = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_ERROR, payload: {} });
  };
};

// EDIT Resets
export const resetEditWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_WORK_SITE_DATA, payload: {} });
  };
};

export const resetEditWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.EDIT_WORK_SITE_ERROR, payload: {} });
  };
};

// DELETE Resets
export const resetDeleteWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_WORK_SITE_DATA, payload: {} });
  };
};

export const resetDeleteWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.DELETE_WORK_SITE_ERROR, payload: {} });
  };
};

// GET Resets
export const resetGetSingleWorksiteError = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_MANAGER_ERROR, payload: {} });
  };
};

export const resetWorkSiteWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_NUMBERS_ERROR, payload: {} });
  };
};

export const resetWorkSiteCallRecordsData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_CALL_RECORDS_DATA, payload: [] });
  };
};

export const resetWorkSiteCallRecordsErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_CALL_RECORDS_ERROR, payload: {} });
  };
};

export const resetWorkSiteCompletedVisitsErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_COMPLETED_VISITS_ERROR, payload: {} });
  };
};

export const resetWorkSiteWorkOrderSummaryErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_COMPLETED_VISITS_ERROR, payload: {} });
  };
};

export const resetWorkSitePhoneNumbersErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_WORK_SITE_PHONE_NUMBERS_ERROR, payload: {} });
  };
};

export const resetGetCallRecordsByWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetGetCallRecordsByWorkOrderNumberError = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetWorkSitesErrorData = () => {
  return (dispatch) => {
    dispatch({ type: WorkSiteDataActions.GET_ALL_WORK_SITES_ERROR, payload: {} });
  };
};

