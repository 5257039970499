import React from 'react'
import PageContainer from '../UIElements/Containers';

const Profile = () => {
  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <h4>Admin Profile</h4>
          </PageContainer>
        </div>
      </div>
    </div>
  )
}

export default Profile