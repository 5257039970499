const AdminAuthActions = {
  USER_LOGGED_DATA: 'USER_LOGGED_DATA',
  USER_LOGGED_ERROR: 'USER_LOGGED_ERROR',
  USER_TYPE_DATA: 'USER_TYPE_DATA',
  USER_ID_DATA: 'USER_ID_DATA',
  USER_RESET_TOKEN:'USER_RESET_TOKEN',
  USER_RESET_TOKEN_ERROR: 'USER_RESET_TOKEN_ERROR',
  USER_RESET_TOKEN_VALIDATION: 'USER_RESET_TOKEN_VALIDATION',
  USER_RESET_TOKEN_VALIDATION_ERROR: 'USER_RESET_TOKEN_VALIDATION_ERROR'
};

export default AdminAuthActions;
