import React, { useState, useEffect } from "react";

// import Dashboard from "../components/Theme-Simple/Dashboard";
import Social from "../components/Social";
import Email from "../components/Email";
import ComposeEmail from "../components/ComposeEmail";
import Cards from "../components/Card";
import Views from "../components/View";
import Chart from "../components/Chart";

import Calendar from "../components/Calendar/Basic";
import CalendarLang from "../components/Calendar/Languages";
import CalendarMonth from "../components/Calendar/Month";
import CalendarLazy from "../components/Calendar/Lazyload";
import CalendarDocs from "../components/Calendar/Documentation";

import Builder from "../components/Builder";

import LayoutsBox from "../components/Layouts/Box";
import LayoutsDefault from "../components/Layouts/Default";
import LayoutsRtl from "../components/Layouts/Rtl";
import LayoutsSecondary from "../components/Layouts/Secondary";

import UIElementsColor from "../components/Theme-Simple/Color";
import UIElementstypography from "../components/UIElements/Typography";
import UIElementsIcons from "../components/UIElements/Icons";
import UIElementsButtons from "../components/UIElements/Buttons";
import UIElementsNotify from "../components/UIElements/Notification";
import UIElementsModal from "../components/UIElements/Modals";
import UIElementsProgressActivity from "../components/UIElements/ProgressAndActivity";
import UIElementsTabsAccordion from "../components/UIElements/TabsAndAccordion";
import UIElementsSliders from "../components/UIElements/Sliders";
import UIElementsTreeView from "../components/UIElements/TreeView";
import UIElementsNestables from "../components/UIElements/Nestables";

import FormElement from "../components/Forms/Elements";
import FormLayout from "../components/Forms/FormLayouts";
import FormWizard from "../components/Forms/Wizard";

import TableBasic from "../components/Tables/Basic";
import TableData from "../components/Tables/Data";

import MapGoogle from "../components/Maps/GoogleMap";
import MapVector from "../components/Maps/VectorMap";

import ExtraInvoice from "../components/Extra/Invoice";
import Extra400 from "../components/Extra/404Page";
import Extra500 from "../components/Extra/500Page";
import ExtraBlank from "../components/Extra/BlankPage";
import ExtraLogin from "../components/Extra/Login";
import ExtraRegister from "../components/Extra/register";
import ExtraLockScreen from "../components/Extra/LockScreen";
import ExtraGallery from "../components/Extra/Gallery";
import ExtraTimeLine from "../components/Extra/TimeLine";

import MenuLevelOne from "../components/MenuLevel/LevelOne";
import Submenu from "../components/MenuLevel/LevelTwo/Submenu";

import Search from "../components/Theme-Simple/Search";
import Header from "../components/Theme-Simple/Header";
import Sidebar from "../components/Theme-Simple/SidebarSimple";
import { useLocation, useNavigate, useParams, useMatch } from "react-router-dom";


import CallRecords from "../components/admin-pages/Visits/AllVisits";
import WorkOrder from "../components/admin-pages/WorkOrder";
import PhoneNumber from "../components/admin-pages/PhoneNumber";
import LogRecords from "../components/admin-pages/LogRecords";
import Dashboard from "../components/admin-pages/Dashboard";
import Profile from "../components/admin-pages/Profile";
import Settings from "../components/admin-pages/Settings";
import Corporate from "../components/admin-pages/Corporate";
import WorkSite from "../components/admin-pages/worksites/WorkSite";
import adminCallRecordsByWorkOrderNumber from "../components/admin-pages/CallRecordsByWorkOrderNumber";
import adminCompletedVisits from '../components/admin-pages/reports/CompletedVisits';
import adminCheckInVisits from '../components/admin-pages/reports/CheckInVisits';
import adminSummaryVisits from '../components/admin-pages/reports/SummaryVisits';
import InActiveWorkSitesForAdmin from "../components/admin-pages/worksites/InActiveWorksites";
import EditToActiveWorkSitesForAdmin from "../components/admin-pages/worksites/EditToActiveWorksites";
import EditToInActiveWorkSitesForAdmin from "../components/admin-pages/worksites/EditToInActiveWorksites";

import corporateCallRecords from '../components/corporate-pages/CallRecords';
import allVisits from '../components/corporate-pages/Visits/AllVisits';
import corporateWorkOrder from '../components/corporate-pages/WorkOrder';
import corporatePhoneNumber from '../components/corporate-pages/PhoneNumber';
import corporateDashboard from '../components/corporate-pages/Dashboard';
import corporateProfile from '../components/corporate-pages/Profile';
import corporateSettings from '../components/corporate-pages/Settings';
import corporateCorporate from '../components/corporate-pages/Corporate';
import corporateWorkSite from '../components/corporate-pages/worksites/WorkSite';
import corporateCallRecordsByWorkOrderNumber from "../components/corporate-pages/CallRecordsByWorkOrderNumber";
import corporateCompletedVisits from '../components/corporate-pages/reports/CompletedVisits';
import corporateCheckInVisits from '../components/corporate-pages/reports/CheckInVisits';
import corporateSummaryVisits from '../components/corporate-pages/reports/SummaryVisits';
import InActiveWorkSite from "../components/corporate-pages/worksites/InActiveWorksites";
import EditToActiveWorkSite from "../components/corporate-pages/worksites/EditToActiveWorksites";
import EditToInActiveWorkSite from "../components/corporate-pages/worksites/EditToInActiveWorksites";

import worksiteCallRecords from '../components/worksite-pages/CallRecords';
import worksiteAllVisits from '../components/worksite-pages/Visits/AllVisits';
import worksiteWorkOrder from '../components/worksite-pages/WorkOrder';
import worksitePhoneNumber from '../components/worksite-pages/PhoneNumber';
import worksiteDashboard from '../components/worksite-pages/Dashboard';
import worksiteProfile from '../components/worksite-pages/Profile';
import worksiteSettings from '../components/worksite-pages/Settings';
import worksiteCallRecordsByWorkOrderNumber from "../components/worksite-pages/CallRecordsByWorkOrderNumber";
import ForgotPassword from "../components/Extra/Login/ForgotPassword";
import ResetPassword from "../components/Extra/Login/ResetPassword";
import worksiteCompletedVisits from '../components/worksite-pages/reports/CompletedVisits';
import worksiteCheckInVisits from '../components/worksite-pages/reports/CheckInVisits';
import worksiteSummaryVisits from "../components/worksite-pages/reports/SummaryVisits";
import DeletedCallRecords from "../components/corporate-pages/Visits/DeletedLogs";
import VisitLogs from "../components/corporate-pages/Visits/VisitsLogs";
import WorkSiteVisitLogs from "../components/worksite-pages/Visits/VisitsLogs";
import RemovedVisits from "../components/worksite-pages/Visits/DeletedVisits";
import AdminVisitsLogs from "../components/admin-pages/Visits/VisitsLogs";
import DeletedVisits from "../components/admin-pages/Visits/DeletedVisits";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

function MatchPath({ path, Comp }) {
	let match = useMatch(path);
	return match ? <Comp {...match} /> : null;
}


const Simple = ({ }) => {
	let path = location.pathname;
	const [toggleInboxHeader, setToggleInboxHeader] = useState(false);

	useEffect(() => {
		window.addEventListener("resize", (e) => {
			setToggleInboxHeader(false);
		});
		return () => window.removeEventListener("resize", null);
	});

	if (path.includes("/")) {
		require("../pages/scss/themes/simpleTheme/simple.scss");
		document.body.classList.remove("pace-done");
		document.body.classList.add("mac");
		document.body.classList.add("desktop");
		document.body.classList.add("js-focus-visible");
		document.body.classList.add("menu-unpinned");
		document.body.classList.add("simple");

		const { innerWidth: width, innerHeight: height } = window;
		width < 1200 && width > 991
			? document.body.classList.remove("menu-pin")
			: document.body.classList.add("menu-pin");
	}

	// if (path.includes("/cards")) {
	// 	document.body.classList.add("cards-view-page");
	// }
	// else {
	// 	document.body.classList.remove("cards-view-page");
	// }

	return (
    <div className="h-100">
      {path.includes('/') &&
        !path.includes('/login') && 
        !path.includes('/worksite/forgot-password') &&
        !path.includes('/worksite/reset-password') &&
        !path.includes('/corporate/forgot-password') &&
        !path.includes('/forgot-password') &&
        !path.includes('/corporate/reset-password') &&
        <Sidebar location={location} />}

      {path.includes('/') &&
        !path.includes('/login') &&
        !path.includes('/worksite/forgot-password') &&
        !path.includes('/worksite/reset-password') &&
        !path.includes('/corporate/forgot-password') &&
        !path.includes('/forgot-password') &&
        !path.includes('/corporate/reset-password') &&
        (
          <Header
            location={location}
            inboxHeader={toggleInboxHeader}
            setInboxHeader={(value) => setToggleInboxHeader(value)}
          />
        )}

      {/* ADMIN PAGES START*/}

      <MatchPath path="/login" Comp={ExtraLogin} />
      <MatchPath path="/worksite/forgot-password" Comp={ForgotPassword} />
      <MatchPath path="/worksite/reset-password/:token" Comp={ResetPassword} />
      <MatchPath path="/Corporate/forgot-password" Comp={ForgotPassword} />
      <MatchPath path="/forgot-password" Comp={ForgotPassword} />
      <MatchPath path="/Corporate/reset-password/:token" Comp={ResetPassword} />


      <MatchPath exact="true" path="/admin/dashboard" Comp={Dashboard} />
      <MatchPath exact="true" path="/admin/all-visits" Comp={CallRecords} />
      <MatchPath exact="true" path="/admin/visits-logs" Comp={AdminVisitsLogs} />
      <MatchPath exact="true" path="/admin/removed-visits" Comp={DeletedVisits} />
      <MatchPath exact="true" path="/admin/work-order" Comp={WorkOrder} />
      <MatchPath exact="false" path="/admin/work-order/:worksiteId/:workOrderId" Comp={adminCallRecordsByWorkOrderNumber} />
      <MatchPath exact="true" path="/admin/phone-number" Comp={PhoneNumber} />
      <MatchPath exact="true" path="/admin/phone-number-verified" Comp={PhoneNumber} />
      <MatchPath exact="true" path="/admin/phone-number-unverified" Comp={PhoneNumber} />
      <MatchPath exact="true" path="/admin/corporate" Comp={Corporate} />
      <MatchPath exact="true" path="/admin/active-sites" Comp={WorkSite} />
      <MatchPath exact="true" path="/admin/log-records" Comp={LogRecords} />
      <MatchPath exact="true" path="/admin/inactive-sites" Comp={InActiveWorkSitesForAdmin} />
      <MatchPath exact="true" path="/admin/edits-to-active-sites" Comp={EditToActiveWorkSitesForAdmin} />
      <MatchPath exact="true" path="/admin/edits-to-inactive-sites" Comp={EditToInActiveWorkSitesForAdmin} />

      <MatchPath exact="true" path="/admin/reports/completed-visits" Comp={adminCompletedVisits} />
      <MatchPath exact="true" path="/admin/reports/check-in-visits" Comp={adminCheckInVisits} />
      <MatchPath exact="true" path="/admin/reports/work-order-summary" Comp={adminSummaryVisits} />
      <MatchPath exact="true" path="/admin/profile" Comp={Profile} />
      <MatchPath exact="true" path="/admin/settings" Comp={Settings} />
      {/* ADMIN PAGES END */}

      {/* CORPORATE PAGES START*/}
      <MatchPath exact="true" path="/corporate/dashboard" Comp={corporateDashboard} />
      <MatchPath exact="true" path="/corporate/all-visits" Comp={allVisits} />
      <MatchPath exact="true" path="/corporate/visits" Comp={corporateCallRecords} />
      <MatchPath exact="true" path="/corporate/removed-visits" Comp={DeletedCallRecords} />
      <MatchPath exact="true" path="/corporate/visits-logs" Comp={VisitLogs} />
      <MatchPath exact="false" path="/corporate/work-order/:worksiteId/:workOrderId" Comp={corporateCallRecordsByWorkOrderNumber} />
      <MatchPath exact="true" path="/corporate/work-order" Comp={corporateWorkOrder} />
      <MatchPath exact="true" path="/corporate/phone-number" Comp={corporatePhoneNumber} />
      <MatchPath exact="true" path="/corporate/phone-number-verified" Comp={corporatePhoneNumber} />
      <MatchPath exact="true" path="/corporate/phone-number-unverified" Comp={corporatePhoneNumber} />
      <MatchPath exact="true" path="/corporate/corporate" Comp={corporateCorporate} />
      <MatchPath exact="true" path="/corporate/active-sites" Comp={corporateWorkSite} />
      <MatchPath exact="true" path="/corporate/inactive-sites" Comp={InActiveWorkSite} />
      <MatchPath exact="true" path="/corporate/edits-to-active-sites" Comp={EditToActiveWorkSite} />
      <MatchPath exact="true" path="/corporate/edits-to-inactive-sites" Comp={EditToInActiveWorkSite} />

      <MatchPath exact="true" path="/corporate/reports/completed-visits" Comp={corporateCompletedVisits} />
      <MatchPath exact="true" path="/corporate/reports/check-in-visits" Comp={corporateCheckInVisits} />
      <MatchPath exact="true" path="/corporate/reports/work-order-summary" Comp={corporateSummaryVisits} />
      <MatchPath exact="true" path="/corporate/profile" Comp={corporateProfile} />
      <MatchPath exact="true" path="/corporate/settings" Comp={corporateSettings} />
      {/* CORPORATE PAGES END */}

      {/* WORKSITE PAGES START*/}
      <MatchPath exact="true" path="/worksite/dashboard" Comp={worksiteDashboard} />
      <MatchPath exact="true" path="/worksite/visits" Comp={worksiteCallRecords} />
      <MatchPath exact="true" path="/worksite/work-order" Comp={worksiteWorkOrder} />
      <MatchPath exact="false" path="/worksite/work-order/:id" Comp={worksiteCallRecordsByWorkOrderNumber} />
      <MatchPath exact="true" path="/worksite/phone-number" Comp={worksitePhoneNumber} />
      <MatchPath exact="true" path="/worksite/phone-number-verified" Comp={worksitePhoneNumber} />
      <MatchPath exact="true" path="/worksite/phone-number-unverified" Comp={worksitePhoneNumber} />
      <MatchPath exact="true" path="/worksite/all-visits" Comp={worksiteAllVisits} />
      <MatchPath exact="true" path="/worksite/visits-logs" Comp={WorkSiteVisitLogs} />
      <MatchPath exact="true" path="/worksite/removed-visits" Comp={RemovedVisits} />

      <MatchPath exact="true" path="/worksite/reports/completed-visits" Comp={worksiteCompletedVisits} />
      <MatchPath exact="true" path="/worksite/reports/check-in-visits" Comp={worksiteCheckInVisits} />
      <MatchPath exact="true" path="/worksite/reports/work-order-summary" Comp={worksiteSummaryVisits} />
      <MatchPath exact="true" path="/worksite/profile" Comp={worksiteProfile} />
      <MatchPath exact="true" path="/worksite/settings" Comp={worksiteSettings} />
      {/* WORKSITE PAGES END */}


      {/* <MatchPath exact="true" path="/simple" Comp={Dashboard} />
      <MatchPath path="/simple/dashboard" Comp={Dashboard} />
      <MatchPath path="/simple/social" Comp={Social} />
      <MatchPath
        path="/simple/email"
        Comp={() => (
          <Email inboxHeader={toggleInboxHeader} location={location} />
        )}
      />
      <MatchPath
        path="/simple/compose_email"
        Comp={() => (
          <ComposeEmail inboxHeader={toggleInboxHeader} location={location} />
        )}
      /> */}
      {/* START Calendar group routes */}
      {/* <MatchPath path="/simple/calendar/basic" Comp={Calendar} />
      <MatchPath path="/simple/calendar/languages" Comp={CalendarLang} />
      <MatchPath path="/simple/calendar/Month" Comp={CalendarMonth} />
      <MatchPath path="/simple/calendar/lazyload" Comp={CalendarLazy} />
      <MatchPath path="/simple/calendar_docs" Comp={CalendarDocs} /> */}
      {/* END Calendar routes */}

      {/* START Builder route */}
      {/* <MatchPath path="/simple/builder" Comp={Builder} /> */}
      {/* END builder route */}

      {/* START Layouts group routes */}
      {/* <MatchPath path="/simple/default_layout" Comp={LayoutsDefault} />
      <MatchPath path="/simple/secondary_layout" Comp={LayoutsSecondary} />
      <MatchPath path="/simple/boxed_layout" Comp={LayoutsBox} />
      <MatchPath path="/simple/rtl_layout" Comp={LayoutsRtl} /> */}
      {/* END Layouts routes */}

      {/* START UIElements group routes */}
      {/* <MatchPath path="/simple/color" Comp={UIElementsColor} />
      <MatchPath path="/simple/typography" Comp={UIElementstypography} />
      <MatchPath path="/simple/icons" Comp={UIElementsIcons} />
      <MatchPath path="/simple/buttons" Comp={UIElementsButtons} />
      <MatchPath path="/simple/notifications" Comp={UIElementsNotify} />
      <MatchPath path="/simple/modals" Comp={UIElementsModal} />
      <MatchPath path="/simple/progress" Comp={UIElementsProgressActivity} />
      <MatchPath path="/simple/tabs_accordian" Comp={UIElementsTabsAccordion} />
      <MatchPath path="/simple/sliders" Comp={UIElementsSliders} />
      <MatchPath path="/simple/tree_view" Comp={UIElementsTreeView} />
      <MatchPath path="/simple/nestables" Comp={UIElementsNestables} /> */}
      {/* END UIElements routes */}

      {/* START Forms group routes */}
      {/* <MatchPath path="/simple/form_elements" Comp={FormElement} />
      <MatchPath path="/simple/form_layouts" Comp={FormLayout} />
      <MatchPath path="/simple/form_wizard" Comp={FormWizard} /> */}
      {/* END Forms routes */}

      {/* <MatchPath path="/simple/cards" Comp={Cards} />
      <MatchPath path="/simple/views" Comp={Views} /> */}

      {/* START Tables group routes */}
      {/* <MatchPath path="/simple/basic_tables" Comp={TableBasic} />
      <MatchPath path="/simple/data_tables" Comp={TableData} /> */}
      {/* END Tables routes */}

      {/* START Maps group routes */}
      {/* <MatchPath path="/simple/google_map" Comp={MapGoogle} />
      <MatchPath path="/simple/vector_map" Comp={MapVector} /> */}
      {/* END Maps routes */}

      {/* <MatchPath path="/simple/charts" Comp={Chart} /> */}

      {/* START Extra group routes */}
      {/* <MatchPath path="/simple/extra/invoice" Comp={ExtraInvoice} />
      <MatchPath path="/simple/extra/404" Comp={Extra400} />
      <MatchPath path="/simple/extra/500" Comp={Extra500} />
      <MatchPath path="/simple/extra/blank_template" Comp={ExtraBlank} />
      <MatchPath path="/simple/extra/login" Comp={ExtraLogin} />
      <MatchPath path="/simple/extra/register" Comp={ExtraRegister} />
      <MatchPath path="/simple/extra/lock_screen" Comp={ExtraLockScreen} />
      <MatchPath path="/simple/extra/gallery" Comp={ExtraGallery} />
      <MatchPath path="/simple/extra/timeline" Comp={ExtraTimeLine} /> */}
      {/* END Extra routes */}

      {/* START Menu Levels group routes */}
      {/* <MatchPath path="/simple/levelOne" Comp={MenuLevelOne} />
      <MatchPath path="/simple/submenu" Comp={Submenu} /> */}
      {/* END Menu Levels routes */}

      {/* {path.includes('/simple') && <Quickview />} */}
      {/* {path.includes('/simple') && !path.includes('/boxed_layout') && (
        <Search />
      )} */}
    </div>
  );
};

export default withRouter(Simple);
