import MailDataActions from '../actions/MailDataAction';

const initialState = {
  postForgotPasswordData: {},
  postForgotPasswordError: {},
};

export default function MailData(state = initialState, action) {
  switch (action.type) {
    // POST Method
    case MailDataActions.POST_FORGOT_PASSWORD_DATA:
      return { ...state, postForgotPasswordData: action?.payload };
    case MailDataActions.POST_FORGOT_PASSWORD_ERROR:
      return { ...state, postForgotPasswordError: action?.payload };

    default:
      return { ...state };
  }
}
