export const tableOneData = [
    {'title':'Life’s sadness shared', 'places':'United States, India, China,Africa', 'activities':'you want English, Scottish, Welsh, Irish, British, European or UK even a British (name other original country you came form or have roots to E.G. A British Japanese or a 5th generation', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'First Tour', 'places':'United States, India, China,Africa', 'activities':'UK was on top of the art world 18-19 century had the best food, best cloths and best entertainment back then) it was a hyper nation', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Second Tour', 'places':'United States, India, China,Africa', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Third Tour', 'places':'United States, India, China,Africa', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'A day to remember', 'places':'United States, India, China,Africa', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Among the children', 'places':'United States, India, China,Africa', 'activities':'he world speaks English. Common law, Magna Carta and the Bill of Rights are its wonderful legacy', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'}   
]

export const tableTwoData = [
    {'title':'First Tour', 'activities':'it is more then ONE nation/nationality as its...'},
    {'title':'Fourth Tour', 'activities':'The world speaks English. Common law, Magna Carta and the Bill of Rights...'},
    {'title':'Second Tour', 'activities':'You want English, Scottish, Welsh, Irish, British, European...'},
    {'title':'Third Tour', 'activities':'UK was on top of the art world 18-19 century had the best food'}
]

export const tableThreeData = [
    {'title':'Fifth tour', 'key':'Simple but not simpl...', 'condensed':'Wonders can be true. Believe...'},
    {'title':'First tour', 'key':'Simple but not simpl...', 'condensed':'Wonders can be true. Believe...'},
    {'title':'Fourth tour', 'key':'Simple but not simpl...', 'condensed':'Wonders can be true. Believe...'},
    {'title':'Second tour', 'key':'Simple but not simpl...', 'condensed':'Wonders can be true. Believe...'},
    {'title':'Third tour', 'key':'Simple but not simpl...', 'condensed':'Wonders can be true. Believe...'}
]