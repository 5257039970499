import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";

import "./blank_page_style.css";

import InputWithLabel from "../../Forms/Elements/InputWithLabel";

import { worksiteChangePassword, resetTokenDataError, corporateChangePassword, resetTokenData } from "../../../api-actions/adminAuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

const ResetPassword = ({ }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const url = useParams();

  const userRole = url['*'];
  const token = userRole.split('/').pop(); // Extracts '12345' from the URL
  const extractedPath = userRole.split("/").slice(0, 2).join("/");


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const passwordChangedSucessfully = useSelector((state) => state.AdminAuthData.passwordChangedData);
  const passwordChangedError = useSelector((state) => state.AdminAuthData.passwordChangedError);

  useEffect(() => {
    if (passwordChangedSucessfully) {
      toast.success("Password has been changed successfully");
      // dispatch(resetTokenData())
    }
  }, [passwordChangedSucessfully])  

  // useEffect(() => {
  //     if (!token) {
  //         navigate('/login'); // Redirect to login if token is not present
  //     }
  // }, [token, navigate]);

  useEffect(() => {
    if (passwordChangedError && Object.keys(passwordChangedError)?.length > 0) {
      toast.error(passwordChangedError?.message);
      dispatch(resetTokenDataError());
    }
  }, [passwordChangedError]);


  let handleWorksiteFormSubmit = () => {
    //Call this function on form submit with no errors
    if (confirmPassword !== "" && password !== "") {
      if (confirmPassword != password) {
        toast.error('Password Not Matched')
      } else {
        if (extractedPath == 'worksite/reset-password') {
          dispatch(worksiteChangePassword({
            token: token,
            password: password,
            confirmPassword: confirmPassword,
          }))
        } else if (extractedPath == 'corporate/reset-password') {
          dispatch(corporateChangePassword({
            token: token,
            password: password,
            confirmPassword: confirmPassword,
          }))
        }
      }
    } else {
      toast.error('Please fill all required field')
    }
  };

  return (
    <div className="login-wrapper ">
      <div className="bg-pic">
        <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
          <h1 className="semi-bold text-white">
            Real-time call logging
          </h1>
          <p className="small">
            Our advanced call record system simplifies employee attendance management. With just a phone call, your team can check in and out securely, while you gain valuable insights into work patterns and productivity.
          </p>
        </div>
      </div>
      <div className="login-container bg-white">
        <div className="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40 cusmobpad">
          <img
            src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
            alt="FM Guardian"
            data-src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
            data-src-retina="/assets/img/fmglogo.png"
            width={'75%'}
            className="mx-auto"
          />

          {/* <p className="mw-80 m-t-5">Sign in </p> */}

          <div className="card card-borderless m-t-25">

            <div className="tab-content">
              <div id="tab2Inspire" >
                <h2 className="p-t-10">
                  <span className="small">Reset Password </span>
                  {/* <br /> Worksite */}
                </h2>
                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleWorksiteFormSubmit}
                  className="p-t-15"
                >
                  <div className="form-group  form-group-default required py-2 d-flex align-items-center justify-content-between">
                    <InputWithLabel
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      value={password}
                      className={'form-control'}
                      // label={'Login'}
                      placeholder="Reset Password"
                      require="true"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {showPassword ? <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i> :
                        <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>}
                    </div>
                  </div>
                  <div className="form-group  form-group-default required py-2 d-flex align-items-center justify-content-between">
                    <InputWithLabel
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      name="confirmPassword"
                      value={confirmPassword}
                      className={'form-control'}
                      // label={'Login'}
                      placeholder="confirm Password"
                      require="true"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {showPassword ? <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i> :
                        <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                      <center>
                        <button
                          aria-label=""
                          className="btn btn-primary btn-lg m-t-10"
                          type="submit"
                        >
                          Reset My Password
                        </button>
                      </center>
                    </div>
                  </div>
                  <div className="m-b-5 m-t-30">

                  </div>
                  {/* <div>
              <a href="javascript:void(0);" className="normal">
                Not a member yet? Signup now.
              </a>
            </div> */}
                </ValidatorForm>
              </div>
            </div>
          </div>




          <div className="pull-bottom sm-pull-bottom w-100">
            <div className="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
              <div className="col-sm-9 no-padding m-t-10">
                <p className="small-text normal hint-text">
                  ©{(new Date().getFullYear())} FM Guardian. All Rights Reserved.<br />
                  <a href="javascript:void(0);">Cookie Policy</a>,{' '}
                  <a href="javascript:void(0);"> Privacy and Terms</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
