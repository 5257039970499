import React from "react";

const NoData = () => {
  return (
    <div className='FMnodata'>
      <img
        src={process.env.PUBLIC_URL + '/assets/img/nodata.gif'}
        alt="FM Guardian"
        data-src={process.env.PUBLIC_URL + '/assets/img/nodata.gif'}
        data-src-retina="/assets/img/nodata.gif"
        className="mx-auto"
      />
      <p className="fs-5 text-center text-gray-600">NO DATA</p>
    </div>
  );
};

export default NoData;
