export const xAxisData = [
	1025409600000, 1028088000000, 1030766400000, 1033358400000,
	1036040400000, 1038632400000, 1041310800000, 1043989200000,
	1046408400000, 1049086800000, 1051675200000, 1054353600000,
	1056945600000, 1059624000000, 1062302400000, 1064894400000,
	1067576400000, 1070168400000, 1072846800000, 1075525200000,
	1078030800000, 1080709200000, 1083297600000, 1085976000000,
	1088568000000, 1091246400000, 1093924800000, 1096516800000,
	1099195200000, 1101790800000, 1104469200000, 1107147600000,
	1109566800000, 1112245200000, 1114833600000, 1117512000000,
	1120104000000, 1122782400000, 1125460800000, 1128052800000,
	1130734800000, 1133326800000, 1136005200000, 1138683600000,
	1141102800000, 1143781200000, 1146369600000, 1149048000000,
	1151640000000, 1154318400000, 1156996800000, 1159588800000,
	1162270800000, 1164862800000, 1167541200000, 1170219600000,
	1172638800000, 1175313600000, 1177905600000, 1180584000000,
	1183176000000, 1185854400000, 1188532800000, 1191124800000,
	1193803200000, 1196398800000, 1199077200000, 1201755600000,
	1204261200000, 1206936000000, 1209528000000, 1212206400000,
	1214798400000, 1217476800000, 1220155200000, 1222747200000,
	1225425600000, 1228021200000, 1230699600000, 1233378000000,
	1235797200000, 1238472000000, 1241064000000, 1243742400000,
	1246334400000, 1249012800000, 1251691200000, 1254283200000,
	1256961600000, 1259557200000, 1262235600000, 1264914000000,
	1267333200000, 1270008000000, 1272600000000, 1275278400000,
	1277870400000, 1280548800000, 1283227200000, 1285819200000,
	1288497600000, 1291093200000, 1293771600000, 1296450000000,
	1298869200000, 1301544000000, 1304136000000, 1306814400000,
	1309406400000, 1312084800000, 1314763200000, 1317355200000,
	1320033600000, 1322629200000, 1325307600000, 1327986000000,
	1330491600000,
];

export const yAxisData = [
	{ value: 0 },
	{ value: 20 },
	{ value: 40 },
	{ value: 60 },
	{ value: 80 },
	{ value: 100 },
	{ value: 120 },
  ];

export const seriesOneData = [
	0, -6.3382185140371, -5.9507873460847, -11.569146943813,
	-5.4767332317425, 0.50794682203014, -5.5310285460542,
	-5.7838296963382, -7.3249341615649, -6.7078630712489,
	0.44227126150934, 7.2481659343222, 9.2512381306992, 11.341210982529,
	14.73482040902, 12.387148007542, 18.436471461827, 19.830742266977,
	22.643205829887, 26.743156781239, 29.597478802228, 30.831697585341,
	28.054068024708, 29.294079423832, 30.269264061274, 24.934526898906,
	24.265982759406, 27.217794897473, 30.802601992077, 36.331003758254,
	43.14249870006, 40.558263931958, 42.5436223858, 41.683584710331,
	36.375367302328, 40.71968898073, 43.897963036919, 49.797033975368,
	47.085993935989, 46.601972859745, 41.567784572762, 47.296923737245,
	47.64296961208, 50.781515820954, 52.600229204305, 55.599684490628,
	57.920388436633, 53.503593218971, 53.522973979964, 49.846822298548,
	54.72134161465, 58.186236223191, 63.908065540997, 69.767285129367,
	72.534013373592, 77.991819436573, 78.14358440499, 83.702398665233,
	91.140859312418, 98.590960607028, 96.245634754228, 92.326364432615,
	97.06876533223, 105.8102555626, 114.38348777791, 103.5960494981,
	101.72488429307, 89.840147735028, 86.963597532664, 84.075505208491,
	93.170105645831, 103.62838083121, 87.458241365091, 85.808374141319,
	93.158054469193, 65.97325238236, 44.580686638224, 36.418977140128,
	38.727678144761, 36.692674173387, 30.03302280948, 36.707532162718,
	52.191457688389, 56.357883979735, 57.629002180305, 66.650985790166,
	70.839243432186, 78.731998491499, 72.375528540349, 81.73838788163,
	87.539792394232, 84.320762662273, 90.621278391889, 102.47144881651,
	102.79320353429, 90.529736050479, 76.580859994531, 86.548979376972,
	81.879653334089, 101.72550015956, 107.9796485226, 106.16240630785,
	114.84268599533, 121.60793322282, 133.41437346605, 125.46646042904,
	129.76784954301, 128.15798861044, 121.92388706072, 116.7003610087,
	88.367701837033, 59.159665765725, 79.793568139753, 75.903834028417,
	72.704218209157, 84.936990804097, 93.38814867074,
  ];

export const seriesTwoData = [
	-0.99623230097881, -3.3475370235594, -3.8187026520342,
	-4.2354146250353, -5.6795404292885, -6.2928665328172,
	-6.8549277434419, -6.9925308360918, -8.3216548655839,
	-7.7682867271435, -6.9244213301058, -5.7407624451404,
	-2.1813149077927, -2.9407596325999, -1.1130607112134,
	-2.0274822307752, -1.8372559072154, -4.0732815531148,
	-6.4417038470291, -2.9407596325999, -1.1130607112134,
	-2.0274822307752, -1.8372559072154, -4.0732815531148,
	-6.4417038470291, -0.049184266875945, -0.10757569491991,
	-0.075601531307242, -0.061245277988149, -0.068227316401169,
	-0.11242758058502, -0.07484843940827, -0.11465623676497,
	-0.24370633342416, -0.21523268478893, -0.37859370911822,
	-0.41932884345151, -0.45393735984802, -0.50868179522598,
	-0.48164396881207, -0.41605962887194, -0.4849034849024,
	-0.55071036101311, -0.67489170505394, -0.74978070939342,
	-0.86395050745343, -0.78524898506764, -0.99800440950854,
	-1.1177951153878, -1.4119975432964, -1.2409959736465,
	-1.3088936375431, -1.5495785469683, -1.1563414981293,
	-0.87192471725994, -0.84073995183442, -0.8876189286737,
	-0.81748513917485, -1.2874081041274, -1.9234702981339,
	-1.8377768147648, -2.710765403183, -2.6493268125418, -3.0814553134551,
	-3.8509837783574, -5.2919167850718, -5.2297750650773,
	-3.9335668501451, -2.3695525190114, -2.3084243151854,
	-3.0753680726738, -2.2346609938962, -3.0598810361615,
	-1.8410154270386, -1.647944203862, -1.929385862278, -3.0769590460943,
	-4.2423933501421, -2.6951491617768, -2.8981825939957,
	-2.9662727940324, -0.21556750497498, -2.6784995167088,
	-4.1296711248958, -3.7311068218734, -4.7695330866954, -5.191913304099,
	-4.102585604566, -2.8498939666225, -2.8106017222851, -2.8456526669963,
	-0.65563070754298, -0.30022343874633, -1.1600358228964,
	-0.26674408835052, -1.4693389757812, -2.7855421590594,
	-1.2668244065703, -2.5537804115548, -4.9144552474502,
	-6.0484408234831, -3.383434903375, -0.46752826932523,
	-1.8030186027963, -0.99623230097881, -3.3475370235594,
	-3.8187026520342, -4.2354146250353, -5.6795404292885,
	-6.2928665328172, -6.8549277434419, -6.9925308360918,
	-8.3216548655839, -7.7682867271435, -6.9244213301058,
	-5.7407624451404, -2.1813149077927, -2.9407596325999,
	-1.1130607112134, -2.0274822307752, -1.8372559072154,
	-4.0732815531148, -6.4417038470291,
  ];

  export const seriesThreeData = [
	-7.0674410638835, -14.663359292964, -14.10439306054, -23.114477037218,
	-16.774256687841, -11.902028464, -16.883038668422, -19.104223676831,
	-20.420523282736, -19.660555051587, -13.106911231646,
	-8.2448460302143, -7.0313058730976, -5.1485118700389,
	-3.0011028761469, -4.1367265281467, 1.5425209565025, 2.7673533607299,
	7.707711475536, 9.7565015112434, 11.396888609473, 10.013964745578,
	8.0558890950562, 9.6081966657458, 11.918590426432, 7.9945345523982,
	8.3201276776796, 9.8283954846342, 11.52712585965, 16.413657596527,
	20.393798297928, 17.456308413907, 20.087778400999, 17.988336990817,
	15.378490151331, 19.47432293573, 20.013851070354, 24.749943726975,
	23.558710274826, 24.558915040889, 22.355860488034, 27.138026265756,
	27.202220808591, 31.219437344964, 31.392355525125, 33.373099232542,
	35.095277582309, 30.923356507615, 31.083717332561, 31.290690671561,
	34.247769216679, 37.41107317762, 42.079177096411, 44.978191659648,
	46.71327102531, 49.203892437699, 46.684723471826, 48.3854589735,
	54.660197840305, 60.311838415602, 57.583282204682, 52.425398898751,
	54.663538086985, 60.181844325224, 62.877219773621, 55.760611512951,
	54.735280367784, 45.495912959474, 40.934919015876, 40.303777633187,
	47.403740368773, 49.951960898839, 37.534590035098, 36.405758293321,
	38.545373001858, 26.106358664455, 4.2658006768744, -3.5517839867557,
	-2.0878920761513, -10.408879093829, -19.924242196038,
	-12.906491912782, -3.9774866468346, 1.0319171601402, 1.3109350357718,
	9.1668309061935, 13.121178985954, 17.578680237511, 14.971294355085,
	21.551327027338, 24.592328423819, 20.158087829555, 24.135661929185,
	31.815205405903, 34.389524768466, 23.785555857522, 17.082756649072,
	25.2480077271, 19.415179069165, 30.413636349327, 35.35795296455,
	35.886413535859, 45.003601951959, 48.27489356402, 53.562864914648,
	54.108274337412, 58.618190111927, 56.806793965598, 54.135477252994,
	50.735258942442, 42.208170945813, 31.617916826724, 46.492005006737,
	46.203116922145, 47.541427643137, 54.518998440993, 61.099720234693,
  ];