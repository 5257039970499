export const formatDate = (dateStr) => {
  const date = new Date(dateStr);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // 12-hour time format
  };

  // Format the date
  const formattedDate = date?.toLocaleString('en-US', options);

  return formattedDate;
};

export const nullToEmpty = (data) => {
  return data?.map((item, i) => {
    let updatedItem = {};

    for (let key in item) {
      updatedItem[key] = item[key] === null ? "" : item[key];
      if ((key === 'checkIn' || key === 'checkOut' || key === 'createdAt') && updatedItem[key]) {
        updatedItem[key] = formatDate(updatedItem[key]);
      } 
    }

    return {
      ...updatedItem,
      index: i + 1
    };
  });
}