import React, { useState, useEffect } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PageContainer from '../UIElements/Containers';

import { deleteCorporateRecord, getAllCorporateRecords, editRecordByCorporate, postCorporateRecord, resetDeleteCorporateRecordData, resetDeleteCorporateRecordErrorData, resetEditCorporateRecordData, resetCorporateRecordsErrorData, resetPostCorporateRecordData, resetPostCorporateRecordErrorData } from '../../api-actions/corporateDataAction';
import { useDispatch, useSelector } from 'react-redux';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import ButtonSpinner from '../Spinner/ButtonSpinner';

const Corporate = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();
  const corporateRecordEmptyState = { corporateName: '', corporateEmail: '', corporatePhoneNumber: '', corporateTollFreeNumber: '' };

  const allCorporateRecordsData = useSelector((state) => state.CorporateData.allCorporateRecordsData);
  const corporateRecordsErrorData = useSelector((state) => state.CorporateData.getAllCorporateRecordsError);
  const postCorporateRecordData = useSelector((state) => state.CorporateData.postCorporateRecordData);
  const postCorporateRecordError = useSelector((state) => state.CorporateData.postCorporateRecordError);
  const editCorporateRecordData = useSelector((state) => state.CorporateData.editCorporateRecordData);
  const editCorporateRecordError = useSelector((state) => state.CorporateData.editCorporateRecordError);
  const deleteCorporateRecordData = useSelector((state) => state.CorporateData.deleteCorporateRecordData);
  const deleteCorporateRecordError = useSelector((state) => state.CorporateData.deleteCorporateRecordError);

  const [corporateWithIndex, setCorporateWithIndex] = useState([])
  const [corporateRecord, setCorporateRecord] = useState(corporateRecordEmptyState)
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentCorporateId, setCurrentCorporateId] = useState(null);
  const [corporateLoader, setCorporateLoader] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  useEffect(() => {
    dispatch(getAllCorporateRecords(jwtToken));
  }, [])

  useEffect(() => {
    const data = allCorporateRecordsData?.map((corporate, i) => (
      { ...corporate, index: i + 1 }
    ))
    setCorporateWithIndex(data)
  }, [allCorporateRecordsData]);

  useEffect(() => {
    if (corporateRecordsErrorData && Object.keys(corporateRecordsErrorData)?.length > 0) {
      dispatch(resetPostCorporateRecordErrorData());
    }
  }, [corporateRecordsErrorData]);

  useEffect(() => {
    if (postCorporateRecordData && Object.keys(postCorporateRecordData)?.length > 0) {
      setCorporateLoader(false)
      setCorporateRecord(corporateRecordEmptyState)
      toast.success('Corporate record added successfully')
      setSlideUpVisible(false)
      dispatch(getAllCorporateRecords(jwtToken));
      dispatch(resetPostCorporateRecordData())
    }
  }, [postCorporateRecordData])

  useEffect(() => {
    if (postCorporateRecordError && Object.keys(postCorporateRecordError)?.length > 0) {
      setCorporateLoader(false)
      toast.error(postCorporateRecordError.message)
      dispatch(resetPostCorporateRecordErrorData());
    }
  }, [postCorporateRecordError]);

  useEffect(() => {
    if (editCorporateRecordData && Object.keys(editCorporateRecordData)?.length > 0) {
      setCorporateRecord(corporateRecordEmptyState);
      setCorporateLoader(false)
      toast.success('Corporate record updated successfully');
      setSlideUpVisible(false);
      dispatch(getAllCorporateRecords(jwtToken));
      dispatch(resetEditCorporateRecordData());
    }
  }, [editCorporateRecordData]);

  useEffect(() => {
    if (editCorporateRecordError && Object.keys(editCorporateRecordError)?.length > 0) {
      setCorporateLoader(false)
      toast.error(editCorporateRecordError.message);
      dispatch(resetCorporateRecordsErrorData());
    }
  }, [editCorporateRecordError]);

  useEffect(() => {
    if (deleteCorporateRecordData && Object.keys(deleteCorporateRecordData)?.length > 0) {
      toast.success('Corporate record deleted successfully');
      dispatch(getAllCorporateRecords(jwtToken));
      dispatch(resetDeleteCorporateRecordData());
    }
  }, [deleteCorporateRecordData]);

  useEffect(() => {
    if (deleteCorporateRecordError && Object.keys(deleteCorporateRecordError)?.length > 0) {
      toast.error(deleteCorporateRecordError.message);
      dispatch(resetDeleteCorporateRecordErrorData());
    }
  }, [deleteCorporateRecordError]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("500");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCorporateRecord((prevCorporateRecord) => ({
      ...prevCorporateRecord,
      [name]: value
    }));
  }

  const handleAddOrEdit = () => {
    if (isEditMode) {
      setCorporateLoader(true)
      dispatch(editRecordByCorporate(jwtToken, currentCorporateId, corporateRecord));
    } else {
      setCorporateLoader(true)
      dispatch(postCorporateRecord(jwtToken, corporateRecord));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setCorporateRecord(corporateRecordEmptyState);
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setIsEditMode(true)
    setCurrentCorporateId(row.id)
    setSlideUpVisible(true)
    setCorporateRecord({
      corporateName: row.name,
      corporateEmail: row.email,
      corporatePhoneNumber: row.phoneNumber,
      corporateTollFreeNumber: row.tollFreeNumber,
    })
  }

  const handleDeleteClick = (row) => {
    setCurrentCorporateId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleDelete = () => {
    if (currentCorporateId) {
      dispatch(deleteCorporateRecord(jwtToken, currentCorporateId))
      setSlideUpVisibleForDelete(false)
    }
  }

  const options = {
    page: currentPage, // Ensure currentPage is used
    sizePerPage: sizePerPage, // Set the current size per page
    onSizePerPageChange: (sizePerPage, page) => {
      setSizePerPage(sizePerPage);
      setCurrentPage(page); // Update the page when size changes
    },
    onPageChange: (page) => setCurrentPage(page),
    sizePerPageRenderer,
  };

  const tableColumns = [
    {
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (currentPage - 1) * sizePerPage + rowIndex + 1; // Adjust row number based on current page
        return rowNumber;
      },
      csvExport: false,
      sortable: false,
      style: { width: '50px' },
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone Number',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'tollFreeNumber',
      text: 'Toll Free Number',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between mb-3 titWbtn">
                <h3 class="text-primary no-margin text-uppercase">Corporate</h3>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT">
                      <button aria-label="" className="btn btn-primary btn-lg m-b-10" type="submit" onClick={handleAddClick}>
                        + Add Corporate
                      </button>

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body mt-2">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block mt-2 mt-md-0" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>{isEditMode ? 'Edit corporate record' : 'Add corporate record'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body px-4 px-md-5 py-4">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    <label for='corporateName'>Name</label>
                                    <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Name' : 'Enter Name'} value={corporateRecord.corporateName} onChange={handleInputChange} name='corporateName' id='corporateName' />

                                    <label for='corporateEmail' className='mt-3'>Email</label>
                                    <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Email' : 'Enter Email'} value={corporateRecord.corporateEmail} onChange={handleInputChange} name='corporateEmail' id='corporateEmail' />

                                    <label for='corporatePhoneNumber' className='mt-3'>Phone Number</label>
                                    <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Phone Number' : 'Enter Phone Number'} value={corporateRecord.corporatePhoneNumber} onChange={handleInputChange} name='corporatePhoneNumber' id='corporatePhoneNumber' />

                                    <label for='corporateTollFreeNumber' className='mt-3'>Toll Free Number</label>
                                    <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Toll Free Number' : 'Enter Toll Free Number'} value={corporateRecord.corporateTollFreeNumber} onChange={handleInputChange} name='corporateTollFreeNumber' id='corporateTollFreeNumber' />

                                    {!isEditMode && (
                                      <>
                                        <label htmlFor='corporatePassword' className='mt-3'>Password</label>
                                        <input type="password" className="form-control w-100" placeholder='Enter Password' value={corporateRecord.corporatePassword} onChange={handleInputChange} name='corporatePassword' id='corporatePassword' />
                                      </>
                                    )}

                                  </div>

                                </form>
                              </div>

                              <div className='modal-footer'>
                                {!corporateLoader ?
                                  <button aria-label="" type="button" className="btn btn-primary" onClick={handleAddOrEdit}>
                                    {isEditMode ? 'Update' : '+ Add'}
                                  </button>
                                  :
                                  <button aria-label="" type="button" className="btn btn-primary">
                                    <ButtonSpinner />
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body custable">
                {corporateWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={corporateWithIndex}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'corporate-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => (
                      <React.Fragment>
                        <ExportCSVButton {...props.csvProps}>
                          Export CSV
                        </ExportCSVButton>
                        <BootstrapTable
                          {...props.baseProps}
                          hover={true}
                          bootstrap4
                          bordered={false}
                          condensed={true}
                          striped={true}
                          // pagination={paginationFactory()}
                          pagination={paginationFactory(options)}
                        // cellEdit={cellEditFactory({
                        //   mode: 'click',
                        //   blurToSave: true,
                        // })}
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                ) : (
                  <p className="fs-5 text-center text-gray-600">NO DATA</p>
                )}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default Corporate;
