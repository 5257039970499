import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlideDown } from "react-slidedown";
import PerfectScrollbar from 'react-perfect-scrollbar';

import "react-slidedown/lib/slidedown.css";
import SidebarItem from "./SidebarItem";
import "./style.scss";
import { getCorporateRecord } from "../../../api-actions/corporateDataAction";
import { getWorksiteTollFree } from "../../../api-actions/workSiteDataAction";

const Component = ({ location }) => {
  const jwtToken = localStorage.getItem('jwtToken');

  const [isVisible, setIsVisible] = useState(false);
  const [shouldExpand, setShouldExpand] = useState(true);
  const [shouldExpandNest, setShouldExpandNest] = useState(false);
  const [clickedMenu, setClickedMenu] = useState("VisitsMap");
  const [clickedMenuNest, setClickedMenuNest] = useState(null);
  const [shouldSideBarPin, setShouldSideBarPin] = useState(false);
  const [shouldToggleSidebarHeader, setShouldToggleSidebarHeader] = useState(false);
  const [shouldBoxedLayout, setShouldBoxedLayout] = useState(false);

  const dispatch = useDispatch();
  const openState = useSelector((state) => state.QuickView.openMobileSideBar);
  const userType = useSelector((state) => state.AdminAuthData.userType);
  const userId = useSelector((state) => state.AdminAuthData.userId);
  const corporateRecordData = useSelector((state) => state.CorporateData.getCorporateRecordData);
  const getTollfreeNumber = useSelector((state) => state.WorkSiteData.getTollfreeNumber);

  useEffect(() => {
    if (userType === 'corporate' && userId) {
      dispatch(getCorporateRecord(jwtToken, userId));
    }

    if (userType === 'worksite' && userId) {
      dispatch(getWorksiteTollFree(jwtToken, userId));
    }
  }, [userType, userId]);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleMenuExpan = (value) => {
    setShouldExpand((prevExpand) => (clickedMenu === value ? !prevExpand : true));
    setClickedMenu(value);
    setClickedMenuNest(null);
  };

  const handleMenuExpanNest = (value) => {
    setShouldExpandNest((prevExpandNest) => !prevExpandNest);
    setClickedMenuNest(value);
  };

  const togglePin = () => {
    setShouldSideBarPin((prevPin) => !prevPin);
    document.body.classList.toggle("menu-pin", !shouldSideBarPin);
  };

  const toggleSidebarHeader = () => {
    setShouldToggleSidebarHeader((prevToggle) => !prevToggle);
  };

  const navBoxedLayout = () => {
    setShouldBoxedLayout((prevBoxedLayout) => !prevBoxedLayout);
    document.body.classList.toggle("box-layout menu-pin  mac desktop js-focus-visible", !shouldBoxedLayout);
  };

  const style = {
    transform: `translate3d(${isVisible ? 280 - 70 : 0}px, 0,0)`,
  };

  const toggleHeader = shouldToggleSidebarHeader ? "sidebar-overlay-slide from-top show" : "sidebar-overlay-slide from-top";

  const shouldViewHeader = !["/extra/404", "/extra/500", "/extra/login", "/extra/register", "/extra/lock_screen"].includes(window.location.pathname);

  const isBoxedLayout = window.location.pathname.includes("/boxed_layout");
  if (isBoxedLayout) {
    document.body.classList.remove("dashboard");
    document.body.classList.add("box-layout", "menu-pin", "mac", "desktop", "js-focus-visible");
  } else {
    document.body.classList.add("dashboard");
  }

  function formatTollFreeNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    return `+${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }


  return (
    <nav
      style={shouldViewHeader ? style : { display: 'none' }}
      className={`page-sidebar page-sidebar-simple ${openState ? 'visible' : ''
        }`}
      data-pages="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* BEGIN SIDEBAR MENU TOP TRAY CONTENT*/}
      <div className={toggleHeader} id="appMenu">
        {/* Top tray content */}
      </div>
      {/* END SIDEBAR MENU TOP TRAY CONTENT*/}

      {/* BEGIN SIDEBAR MENU HEADER*/}
      <div className="sidebar-header">
        <img
          src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
          alt="logo"
          style={openState ? { marginRight: '0px' } : {}}
          className="brand"
          data-src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
          data-src-retina="/assets/img/fmglogo.png"
          width="100%"
        />
      </div>
      {userType === "corporate" && (
        <div className="d-flex align-items-center justify-content-center text-success tollfree">
          <p>{corporateRecordData.tollFreeNumber && formatTollFreeNumber(corporateRecordData.tollFreeNumber)}</p>
        </div>)}

      {userType === "worksite" && (
        <div className="d-flex align-items-center justify-content-center text-success tollfree">
          <p>{getTollfreeNumber.tollFreeNumber && formatTollFreeNumber(getTollfreeNumber.tollFreeNumber)}</p>
        </div>)}
      {/* END SIDEBAR MENU HEADER*/}

      {/* START SIDEBAR MENU */}
      <div className="sidebar-menu">
        {/* BEGIN SIDEBAR MENU ITEMS*/}
        <div className="scroll-wrapper menu-items">
          <PerfectScrollbar className="menu-items scroll-content scroll-scrolly_visible">
            {/* <SidebarItem
              url={`${userType}/visits`}
              title="Visits"
              icon={
                <i className="pg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-list"
                  >
                    <line x1="8" y1="6" x2="21" y2="6"></line>
                    <line x1="8" y1="12" x2="21" y2="12"></line>
                    <line x1="8" y1="18" x2="21" y2="18"></line>
                    <line x1="3" y1="6" x2="3.01" y2="6"></line>
                    <line x1="3" y1="12" x2="3.01" y2="12"></line>
                    <line x1="3" y1="18" x2="3.01" y2="18"></line>
                  </svg>
                </i>
              }
            /> */}
             <li
              className={
                shouldExpand && clickedMenu === 'VisitsMap' ? 'open active' : ''
              }
            >
              <a
                href="javascript:void(0);"
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuExpan('VisitsMap');
                }}
              >
                <span className="title">Visits</span>
                <span
                  className={
                    shouldExpand && clickedMenu === 'VisitsMap'
                      ? 'arrow open active'
                      : ' arrow'
                  }
                ></span>
              </a>
              <span className="icon-thumbnail">
              <i className="pg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-list"
                  >
                    <line x1="8" y1="6" x2="21" y2="6"></line>
                    <line x1="8" y1="12" x2="21" y2="12"></line>
                    <line x1="8" y1="18" x2="21" y2="18"></line>
                    <line x1="3" y1="6" x2="3.01" y2="6"></line>
                    <line x1="3" y1="12" x2="3.01" y2="12"></line>
                    <line x1="3" y1="18" x2="3.01" y2="18"></line>
                  </svg>
                </i>
              </span>
              <SlideDown className={'my-dropdown-slidedown'}>
                {shouldExpand && clickedMenu === 'VisitsMap' && (
                  <ul className="sub-menu sub-menu-simple">
                    <SidebarItem
                      activeClassName="active"
                      subMenuItem
                      url={`${userType}/all-visits`}
                      title="All Visits"
                      icon={
                        <i
                          className="pg-icon"
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-check-circle"
                          >
                            <path d="M9 11l3 3L22 4"></path>
                            <circle cx="12" cy="12" r="10"></circle>
                          </svg>


                        </i>
                      }
                    />
                    <SidebarItem
                      subMenuItem
                      activeClassName="active"
                      url={`${userType}/visits-logs`}
                      title="Visits Logs"
                      icon={
                        <i className="pg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-log-in"
                          >
                            <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                            <polyline points="10 17 15 12 10 7"></polyline>
                            <line x1="15" y1="12" x2="3" y2="12"></line>
                          </svg>

                        </i>
                      }
                    />
                    <SidebarItem
                      subMenuItem
                      activeClassName="active"
                      url={`${userType}/removed-visits`}
                      title="Removed Visits"
                      icon={
                        <i className="pg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-summary"
                          >
                              <rect x="3" y="4" width="18" height="16" rx="2" ry="2"></rect>
                              <line x1="8" y1="10" x2="16" y2="10"></line>
                              <line x1="8" y1="14" x2="16" y2="14"></line>
                              <line x1="8" y1="18" x2="12" y2="18"></line>
                          </svg>

                        </i>
                      }
                    />
                  </ul>
                )}
              </SlideDown>
            </li>
            <li
              className={
                shouldExpand && clickedMenu === 'ReportsMap' ? 'open active' : ''
              }
            >
              <a
                href="javascript:void(0);"
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuExpan('ReportsMap');
                }}
              >
                <span className="title">Reports</span>
                <span
                  className={
                    shouldExpand && clickedMenu === 'ReportsMap'
                      ? 'arrow open active'
                      : ' arrow'
                  }
                ></span>
              </a>
              <span className="icon-thumbnail">
                <i className="pg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-text"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                  </svg>

                </i>
              </span>
              <SlideDown className={'my-dropdown-slidedown'}>
                {shouldExpand && clickedMenu === 'ReportsMap' && (
                  <ul className="sub-menu sub-menu-simple">
                    <SidebarItem
                      activeClassName="active"
                      subMenuItem
                      url={`${userType}/reports/completed-visits`}
                      title="Completed Visits"
                      icon={
                        <i
                          className="pg-icon"
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-check-circle"
                          >
                            <path d="M9 11l3 3L22 4"></path>
                            <circle cx="12" cy="12" r="10"></circle>
                          </svg>


                        </i>
                      }
                    />
                    <SidebarItem
                      subMenuItem
                      activeClassName="active"
                      url={`${userType}/reports/check-in-visits`}
                      title="Check-in Visits"
                      icon={
                        <i className="pg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-log-in"
                          >
                            <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                            <polyline points="10 17 15 12 10 7"></polyline>
                            <line x1="15" y1="12" x2="3" y2="12"></line>
                          </svg>

                        </i>
                      }
                    />
                    <SidebarItem
                      subMenuItem
                      activeClassName="active"
                      url={`${userType}/reports/work-order-summary`}
                      title="Work Order Summary"
                      icon={
                        <i className="pg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-summary"
                          >
                              <rect x="3" y="4" width="18" height="16" rx="2" ry="2"></rect>
                              <line x1="8" y1="10" x2="16" y2="10"></line>
                              <line x1="8" y1="14" x2="16" y2="14"></line>
                              <line x1="8" y1="18" x2="12" y2="18"></line>
                          </svg>

                        </i>
                      }
                    />
                  </ul>
                )}
              </SlideDown>
            </li>
            <SidebarItem
              url={`${userType}/work-order`}
              title="Manage Work Orders"
              icon={
                <i className="pg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-briefcase"
                  >
                    <rect
                      x="2"
                      y="7"
                      width="20"
                      height="14"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M16 3H8v4h8V3z"></path>
                  </svg>
                </i>
              }
            />
            <li
              className={
                shouldExpand && clickedMenu === 'Maps' ? 'open active' : ''
              }
            >
              <a
                href="javascript:void(0);"
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuExpan('Maps');
                }}
              >
                <span className="title">Phone Numbers</span>
                <span
                  className={
                    shouldExpand && clickedMenu === 'Maps'
                      ? 'arrow open active'
                      : ' arrow'
                  }
                ></span>
              </a>
              <span className="icon-thumbnail">
                <i className="pg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-phone"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.88 19.88 0 0 1-8.63-3.1 19.5 19.5 0 0 1-6-6A19.88 19.88 0 0 1 2 4.18 2 2 0 0 1 4 2h3a2 2 0 0 1 2 1.72 13 13 0 0 0 .56 2.57 2 2 0 0 1-.45 2.11L8.91 9.09a16 16 0 0 0 6 6l1.69-1.69a2 2 0 0 1 2.11-.45 13 13 0 0 0 2.57.56A2 2 0 0 1 22 16.92z"></path>
                  </svg>
                </i>
              </span>
              <SlideDown className={'my-dropdown-slidedown'}>
                {shouldExpand && clickedMenu === 'Maps' && (
                  <ul className="sub-menu sub-menu-simple">
                    <SidebarItem
                      activeClassName="active"
                      subMenuItem
                      url={`${userType}/phone-number-verified`}
                      title="Verified Numbers"
                      icon={
                        <i
                          className="pg-icon"
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-phone"
                            style={{ marginRight: '4px' }}
                          >
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-check"
                            style={{
                              position: 'absolute',
                              bottom: '6px',
                              left: '8px',
                            }}
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </i>
                      }
                    />
                    <SidebarItem
                      subMenuItem
                      activeClassName="active"
                      url={`${userType}/phone-number-unverified`}
                      title="Unverified Numbers"
                      icon={
                        <i className="pg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-phonephone-missed"
                          >
                            <line x1="23" y1="1" x2="17" y2="7"></line>
                            <line x1="17" y1="1" x2="23" y2="7"></line>
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                          </svg>
                        </i>
                      }
                    />
                    <SidebarItem
                      subMenuItem
                      activeClassName="active"
                      url={`${userType}/phone-number`}
                      title="All Numbers"
                      icon={
                        <i className="pg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-phone"
                          >
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.88 19.88 0 0 1-8.63-3.1 19.5 19.5 0 0 1-6-6A19.88 19.88 0 0 1 2 4.18 2 2 0 0 1 4 2h3a2 2 0 0 1 2 1.72 13 13 0 0 0 .56 2.57 2 2 0 0 1-.45 2.11L8.91 9.09a16 16 0 0 0 6 6l1.69-1.69a2 2 0 0 1 2.11-.45 13 13 0 0 0 2.57.56A2 2 0 0 1 22 16.92z"></path>
                          </svg>
                        </i>
                      }
                    />
                  </ul>
                )}
              </SlideDown>
            </li>

            {userType === 'admin' && (
              <SidebarItem
                url={`${userType}/corporate`}
                title="Corporate"
                icon={
                  <i className="pg-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-building"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M9 22V12h6v10M3 9h18M3 15h18"></path>
                    </svg>
                  </i>
                }
              />
            )}

            {/* {(userType === 'admin' || userType === 'corporate') && (
              <SidebarItem
                url={`${userType}/work-site`}
                title="Work Site"
                icon={
                  <i className="pg-icon">
                    <svg
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </i>
                }
              />
            )} */}

            {(userType === 'admin' || userType === 'corporate') && (
              <li className={shouldExpand && clickedMenu === 'Worksite' ? 'open active' : ''}>
                <a
                  href="javascript:void(0);"
                  onClick={(e) => {
                    e.preventDefault();
                    handleMenuExpan('Worksite');
                  }}
                >
                  <span className="title">Worksite</span>
                  <span
                    className={
                      shouldExpand && clickedMenu === 'Worksite'
                        ? 'arrow open active'
                        : ' arrow'
                    }
                  ></span>
                </a>
                <span className="icon-thumbnail">
                  <i className="pg-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-briefcase"
                    >
                      <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                    </svg>
                  </i>
                </span>
                <SlideDown className={'my-dropdown-slidedown'}>
                  {shouldExpand && clickedMenu === 'Worksite' && (
                    <ul className="sub-menu sub-menu-simple">
                      <SidebarItem
                        activeClassName="active"
                        subMenuItem
                        url={`${userType}/active-sites`}
                        title="Active Sites"
                        icon={
                          <i className="pg-icon">
                            <svg
                              width="16"
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-check-circle"
                            >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                              <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                          </i>
                        }
                      />
                      <SidebarItem
                        subMenuItem
                        activeClassName="active"
                        url={`${userType}/edits-to-active-sites`}
                        title="Edits to Active Sites"
                        icon={
                          <i className="pg-icon">
                            <svg
                              width="16"
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                          </i>
                        }
                      />
                      <SidebarItem
                        subMenuItem
                        activeClassName="active"
                        url={`${userType}/inactive-sites`}
                        title="Inactive Sites"
                        icon={
                          <i className="pg-icon">
                            <svg
                              width="16"
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-x-circle"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="15" y1="9" x2="9" y2="15"></line>
                              <line x1="9" y1="9" x2="15" y2="15"></line>
                            </svg>
                          </i>
                        }
                      />
                      <SidebarItem
                        subMenuItem
                        activeClassName="active"
                        url={`${userType}/edits-to-inactive-sites`}
                        title="Edits To Inactive Sites"
                        icon={
                          <i className="pg-icon">
                            <svg
                              width="16"
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit-3"
                            >
                              <path d="M12 20h9"></path>
                              <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                            </svg>
                          </i>
                        }
                      />
                    </ul>
                  )}
                </SlideDown>
              </li>
            )}

          </PerfectScrollbar>
          {/* <a
            href="javascript:void(0);"
            className="search-link d-flex justify-content-between align-items-center d-lg-none"
            data-toggle="horizontal-menu"
            id="headerSearchInputSimple"
          >
            Tap here to search
            <i className="pg-icon float-right">search</i>
          </a> */}
        </div>
        <div className="clearfix"></div>
      </div>
    </nav>
  );
};

export default Component;
