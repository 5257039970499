import React, { useEffect } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PageContainer from '../UIElements/Containers';

import { getAllLogRecords, resetLogRecordsErrorData } from '../../api-actions/adminDataActions';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../NoData/NoData';

const LogRecords = () => {
  const tableColumns = [
    {
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      csvExport: false,
      sortable: false,
      style: { width: '50px' },
    },
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'data',
      text: 'Data',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell === 'object') {
          return JSON.stringify(cell);
        }
        return cell;
      }
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
  ];

  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();

  const logRecordsData = useSelector((state) => state.AdminData.logRecordsData);
  const logRecordsErrorData = useSelector((state) => state.AdminData.getlogRecordsError);

  useEffect(() => {
    dispatch(getAllLogRecords(jwtToken));
  }, []);

  useEffect(() => {
    if (logRecordsErrorData && Object.keys(logRecordsErrorData)?.length > 0) {
      dispatch(resetLogRecordsErrorData());
    }
  }, [logRecordsErrorData]);


  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between">
                <div className="card-title"></div>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT btn-group"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {logRecordsData?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={logRecordsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'LogRecords-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => (
                      <React.Fragment>
                        {/* <ExportCSVButton {...props.csvProps}>
                        Export CSV
                      </ExportCSVButton> */}
                        <BootstrapTable
                          {...props.baseProps}
                          hover={true}
                          bootstrap4
                          bordered={false}
                          condensed={true}
                          striped={true}
                          pagination={paginationFactory()}
                        // cellEdit={cellEditFactory({
                        //   mode: 'click',
                        //   blurToSave: true,
                        // })}
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default LogRecords;
