import MailDataActions from '../redux/actions/MailDataAction';
import apiSignUp from '../services/adminSignUp';

export const postForgotPassword = (data) => {
  return async (dispatch) => {
    await apiSignUp
    .post('mails/forgot-password', data)
      .then((res) => {
        dispatch({
          type: MailDataActions.POST_FORGOT_PASSWORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: MailDataActions.POST_FORGOT_PASSWORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};


// POST Resets
export const resetPostForgotPasswordData = () => {
  return (dispatch) => {
    dispatch({ type: MailDataActions.POST_FORGOT_PASSWORD_DATA, payload: {} });
  };
};

export const resetPostForgotPasswordError = () => {
  return (dispatch) => {
    dispatch({ type: MailDataActions.POST_FORGOT_PASSWORD_ERROR, payload: {} });
  };
};