export const cityTags = [
    'Amsterdam',
    'Washington'
]

export const emailTags = [
    'johnsmith@pages.io',
    'johnsmith@pages.io'
]

export const randomWordTags = [
    'Hello World',
    'quotes',
    'inspiration'
]
