export const tabSelectOne = [
    { value: '0', label: 'Hello World' },
    { value: '1', label: 'Hello Two' },
    { value: '2', label: 'Hello Three' }
]

export const tabSelectTwo = [
    { value: '0', label: 'Home' },
    { value: '1', label: 'Profile' },
    { value: '2', label: 'Message' }
]