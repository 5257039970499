import CorporateDataActions from '../actions/CorporateDataAction';

const initialState = {
  getAllCallRecordsData: [],
  getAllCallRecordsError: {},

  getRecentCallRecordsData: [],
  getRecentCallRecordsError: {},

  getDeletedCallRecordsData:[],
  getDeletedCallRecordsError:{},

  getAllWorkOrdersData: [],
  getAllWorkOrdersError: {},

  getAllPhoneNumbersData: [],
  getAllPhoneNumbersError: {},

  allCorporateRecordsData: [],
  getAllCorporateRecordsError: {},

  getCorporateRecordData: {},
  getCorporateRecordError: {},

  getWorkSitesForCorporateData: [],
  getWorkSitesForCorporateError: {},

  getInActiveWorkSitesForCorporateData: [],
  getInActiveWorkSitesForCorporateError: {},

  getCallRecordsByWorkOrderNumberData: {},
  getCallRecordsByWorkOrderNumberError: {},

  getCorporateCompletedVisitsData: [],
  getCorporateCompletedVisitsError: {},
  getCorporateCheckInVisitsData: [],
  getCorporateCheckInVisitsError: {},
  getCorporateVisitsSummaryData: [],
  getCorporateVisitsSummaryError: {},

  getManagerData: {},
  getManagerError: {},

  postCallRecordData: {},
  postCallRecordError: {},
  postPhoneNumberData: {},
  postPhoneNumberError: {},
  postworkOrderNumberData: {},
  postworkOrderNumberError: {},
  postCorporateRecordData: {},
  postCorporateRecordError: {},

  postCorporateWorkOrderNumbersData: {},
  postCorporateWorkOrderNumbersError: {},
  postCorporateWorksitesData: {},
  postCorporateWorksitesError: {},

  editWorkSiteData: {},
  editWorkSiteError: {},
  editCallRecordData: {},
  editCallRecordError: {},
  editPhoneNumberData: {},
  editPhoneNumberError: {},
  editworkOrderNumberData: {},
  editworkOrderNumberError: {},
  editCorporateRecordData: {},
  editCorporateRecordError: {},
  editCorporateUpdatePasswordData: {},
  editCorporateUpdatePasswordError: {},

  updateCheckInCallRecordData: {},
  updateCheckInCallRecordError: {},

  deleteWorkSiteData: {},
  deleteWorkSiteError: {},
  inActiveWorkSiteData: {},
  inActiveWorkSiteError: {},
  deleteCallRecordData: {},
  deleteCallRecordError: {},
  deletePhoneNumberData: {},
  deletePhoneNumberError: {},
  deleteworkOrderNumberData: {},
  deleteworkOrderNumberError: {},
  deleteCorporateRecordData: {},
  deleteCorporateRecordError: {},
};

export default function CorporateData(state = initialState, action) {
  switch (action.type) {
    // GET Method
    case CorporateDataActions.GET_ALL_CALL_RECORDS_DATA:
      return { ...state, getAllCallRecordsData: action?.payload };
    case CorporateDataActions.GET_ALL_CALL_RECORDS_ERROR:
      return { ...state, getAllCallRecordsError: action?.payload };

      case CorporateDataActions.GET_RECENT_CALL_RECORDS_DATA:
        return { ...state, getRecentCallRecordsData: action?.payload };
      case CorporateDataActions.GET_RECENT_CALL_RECORDS_ERROR:
        return { ...state, getRecentCallRecordsError: action?.payload };

      case CorporateDataActions.GET_DELETED_CALL_RECORDS_DATA:
        return { ...state, getDeletedCallRecordsData: action?.payload };
      case CorporateDataActions.GET_DELETED_CALL_RECORDS_ERROR:
        return { ...state, getDeletedCallRecordsError: action?.payload };

    case CorporateDataActions.GET_ALL_WORK_ORDERS_DATA:
      return { ...state, getAllWorkOrdersData: action?.payload };
    case CorporateDataActions.GET_ALL_WORK_ORDERS_ERROR:
      return { ...state, getAllWorkOrdersError: action?.payload };

    case CorporateDataActions.GET_ALL_PHONE_NUMBERS_DATA:
      return { ...state, getAllPhoneNumbersData: action?.payload };
    case CorporateDataActions.GET_ALL_PHONE_NUMBERS_ERROR:
      return { ...state, getAllPhoneNumbersError: action?.payload };

    case CorporateDataActions.GET_ALL_CORPORATE_RECORDS_DATA:
      return { ...state, allCorporateRecordsData: action?.payload };
    case CorporateDataActions.GET_ALL_CORPORATE_RECORDS_ERROR:
      return { ...state, getAllCorporateRecordsError: action?.payload };

    case CorporateDataActions.GET_CORPORATE_RECORD_DATA:
      return { ...state, getCorporateRecordData: action?.payload };
    case CorporateDataActions.GET_CORPORATE_RECORD_ERROR:
      return { ...state, getCorporateRecordError: action?.payload };

    case CorporateDataActions.GET_WORK_SITES_FOR_CORPORATE_DATA:
      return { ...state, getWorkSitesForCorporateData: action?.payload };
    case CorporateDataActions.GET_CORPORATE_RECORD_ERROR:
      return { ...state, getWorkSitesForCorporateError: action?.payload };

    case CorporateDataActions.GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_DATA:
      return { ...state, getInActiveWorkSitesForCorporateData: action?.payload };
    case CorporateDataActions.GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_ERROR:
      return { ...state, getInActiveWorkSitesForCorporateError: action?.payload };

    case CorporateDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA:
      return { ...state, getCallRecordsByWorkOrderNumberData: action?.payload };
    case CorporateDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR:
      return { ...state, getCallRecordsByWorkOrderNumberError: action?.payload };

    case CorporateDataActions.GET_CORPORATE_COMPLETED_VISITS_DATA:
      return { ...state, getCorporateCompletedVisitsData: action?.payload };
    case CorporateDataActions.GET_CORPORATE_COMPLETED_VISITS_ERROR:
      return { ...state, getCorporateCompletedVisitsError: action?.payload };

    case CorporateDataActions.GET_CORPORATE_CHECK_IN_VISITS_DATA:
      return { ...state, getCorporateCheckInVisitsData: action?.payload };
    case CorporateDataActions.GET_CORPORATE_CHECK_IN_VISITS_ERROR:
      return { ...state, getCorporateCheckInVisitsError: action?.payload };

    case CorporateDataActions.GET_CORPORATE_VISITS_SUMMARY_DATA:
      return { ...state, getCorporateVisitsSummaryData: action?.payload };
    case CorporateDataActions.GET_CORPORATE_VISITS_SUMMARY_ERROR:
      return { ...state, getCorporateVisitsSummaryError: action?.payload };

    case CorporateDataActions.GET_WORK_SITE_MANAGER_DATA:
      return { ...state, getManagerData: action?.payload };
    case CorporateDataActions.GET_WORK_SITE_MANAGER_ERROR:
      return { ...state, getManagerError: action?.payload };

    // POST Method
    case CorporateDataActions.POST_CORPORATE_RECORD_DATA:
      return { ...state, postCorporateRecordData: action?.payload };
    case CorporateDataActions.POST_CORPORATE_RECORD_ERROR:
      return { ...state, postCorporateRecordError: action?.payload };
    case CorporateDataActions.POST_CALL_RECORD_DATA:
      return { ...state, postCallRecordData: action?.payload };
    case CorporateDataActions.POST_CALL_RECORD_ERROR:
      return { ...state, postCallRecordError: action?.payload };
    case CorporateDataActions.POST_PHONE_NUMBER_DATA:
      return { ...state, postPhoneNumberData: action?.payload };
    case CorporateDataActions.POST_PHONE_NUMBER_ERROR:
      return { ...state, postPhoneNumberError: action?.payload };
    case CorporateDataActions.POST_WORK_ORDER_NUMBER_DATA:
      return { ...state, postWorkOrderNumberData: action?.payload };
    case CorporateDataActions.POST_WORK_ORDER_NUMBER_ERROR:
      return { ...state, postWorkOrderNumberError: action?.payload };

    case CorporateDataActions.POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_DATA:
      return { ...state, postCorporateWorkOrderNumbersData: action?.payload }
    case CorporateDataActions.POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_ERROR:
      return { ...state, postCorporateWorkOrderNumbersError: action?.payload }

    case CorporateDataActions.POST_CORPORATE_WORK_SITE_USING_CSV_DATA:
      return { ...state, postCorporateWorksitesData: action?.payload }
    case CorporateDataActions.POST_CORPORATE_WORK_SITE_USING_CSV_ERROR:
      return { ...state, postCorporateWorksitesError: action?.payload }

    // EDIT Method
    case CorporateDataActions.EDIT_WORK_SITE_DATA:
      return { ...state, editWorkSiteData: action?.payload };
    case CorporateDataActions.EDIT_WORK_SITE_ERROR:
      return { ...state, editWorkSiteError: action?.payload };
    case CorporateDataActions.EDIT_CORPORATE_RECORD_DATA:
      return { ...state, editCorporateRecordData: action?.payload };
    case CorporateDataActions.EDIT_CORPORATE_RECORD_ERROR:
      return { ...state, editCorporateRecordError: action?.payload };
    case CorporateDataActions.EDIT_CALL_RECORD_DATA:
      return { ...state, editCallRecordData: action?.payload };
    case CorporateDataActions.EDIT_CALL_RECORD_ERROR:
      return { ...state, editCallRecordError: action?.payload };
    case CorporateDataActions.EDIT_PHONE_NUMBER_DATA:
      return { ...state, editPhoneNumberData: action?.payload };
    case CorporateDataActions.EDIT_PHONE_NUMBER_ERROR:
      return { ...state, editPhoneNumberError: action?.payload };
    case CorporateDataActions.EDIT_WORK_ORDER_NUMBER_DATA:
      return { ...state, editWorkOrderNumberData: action?.payload };
    case CorporateDataActions.EDIT_WORK_ORDER_NUMBER_ERROR:
      return { ...state, editWorkOrderNumberError: action?.payload };

    case CorporateDataActions.EDIT_CORPORATE_PASSWORD_DATA:
      return { ...state, editCorporateUpdatePasswordData: action?.payload };
    case CorporateDataActions.EDIT_CORPORATE_PASSWORD_ERROR:
      return { ...state, editCorporateUpdatePasswordError: action?.payload };

    case CorporateDataActions.UPDATE_CHECK_IN_CALL_RECORD_DATA:
      return { ...state, updateCheckInCallRecordData: action?.payload };
    case CorporateDataActions.UPDATE_CHECK_IN_CALL_RECORD_ERROR:
      return { ...state, updateCheckInCallRecordError: action?.payload };

    // DELETE Method
    case CorporateDataActions.DELETE_WORK_SITE_DATA:
      return { ...state, deleteWorkSiteData: action?.payload };
    case CorporateDataActions.DELETE_WORK_SITE_ERROR:
      return { ...state, deleteWorkSiteError: action?.payload };
      case CorporateDataActions.INACTIVE_WORK_SITE_DATA:
        return { ...state, inActiveWorkSiteData: action?.payload };
      case CorporateDataActions.INACTIVE_WORK_SITE_ERROR:
        return { ...state, inActiveWorkSiteError: action?.payload };
    case CorporateDataActions.DELETE_CORPORATE_RECORD_DATA:
      return { ...state, deleteCorporateRecordData: action?.payload };
    case CorporateDataActions.DELETE_CORPORATE_RECORD_ERROR:
      return { ...state, deleteCorporateRecordError: action?.payload };
    case CorporateDataActions.DELETE_CALL_RECORD_DATA:
      return { ...state, deleteCallRecordData: action?.payload };
    case CorporateDataActions.DELETE_CALL_RECORD_ERROR:
      return { ...state, deleteCallRecordError: action?.payload };
    case CorporateDataActions.DELETE_PHONE_NUMBER_DATA:
      return { ...state, deletePhoneNumberData: action?.payload };
    case CorporateDataActions.DELETE_PHONE_NUMBER_ERROR:
      return { ...state, deletePhoneNumberError: action?.payload };
    case CorporateDataActions.DELETE_WORK_ORDER_NUMBER_DATA:
      return { ...state, deleteWorkOrderNumberData: action?.payload };
    case CorporateDataActions.DELETE_WORK_ORDER_NUMBER_ERROR:
      return { ...state, deleteWorkOrderNumberError: action?.payload };

    default:
      return { ...state };
  }
}
