const CorporateDataActions = {
  GET_ALL_CALL_RECORDS_DATA: 'GET_ALL_CALL_RECORDS_DATA',
  GET_ALL_CALL_RECORDS_ERROR: 'GET_ALL_CALL_RECORDS_ERROR',

  GET_RECENT_CALL_RECORDS_DATA: 'GET_RECENT_CALL_RECORDS_DATA',
  GET_RECENT_CALL_RECORDS_ERROR: 'GET_RECENT_CALL_RECORDS_ERROR',

  GET_DELETED_CALL_RECORDS_DATA:'GET_DELETED_CALL_RECORDS_DATA',
  GET_DELETED_CALL_RECORDS_DATA:'GET_DELETED_CALL_RECORDS_ERROR',

  GET_ALL_WORK_ORDERS_DATA: 'GET_ALL_WORK_ORDERS_DATA',
  GET_ALL_WORK_ORDERS_ERROR: 'GET_ALL_WORK_ORDERS_ERROR',

  GET_ALL_PHONE_NUMBERS_DATA: 'GET_ALL_PHONE_NUMBERS_DATA',
  GET_ALL_PHONE_NUMBERS_ERROR: 'GET_ALL_PHONE_NUMBERS_ERROR',

  GET_ALL_CORPORATE_RECORDS_DATA: 'GET_ALL_CORPORATE_RECORDS_DATA',
  GET_ALL_CORPORATE_RECORDS_ERROR: 'GET_ALL_CORPORATE_RECORDS_ERROR',
  GET_CORPORATE_RECORD_DATA: 'GET_CORPORATE_RECORD_DATA',
  GET_CORPORATE_RECORD_ERROR: 'GET_CORPORATE_RECORD_ERROR',
  GET_WORK_SITES_FOR_CORPORATE_DATA: 'GET_WORK_SITES_FOR_CORPORATE_DATA',
  GET_WORK_SITES_FOR_CORPORATE_ERROR: 'GET_WORK_SITES_FOR_CORPORATE_ERROR',

  GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_DATA: 'GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_DATA',
  GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_ERROR: 'GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_ERROR',

  GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA: 'GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA',
  GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR: 'GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR',
  GET_CORPORATE_COMPLETED_VISITS_DATA: 'GET_CORPORATE_COMPLETED_VISITS_DATA',
  GET_CORPORATE_COMPLETED_VISITS_ERROR: 'GET_CORPORATE_COMPLETED_VISITS_ERROR',
  GET_CORPORATE_CHECK_IN_VISITS_DATA: 'GET_CORPORATE_CHECK_IN_VISITS_DATA',
  GET_CORPORATE_CHECK_IN_VISITS_ERROR: 'GET_CORPORATE_CHECK_IN_VISITS_ERROR',
  GET_CORPORATE_VISITS_SUMMARY_DATA: 'GET_CORPORATE_VISITS_SUMMARY_DATA',
  GET_CORPORATE_VISITS_SUMMARY_ERROR: 'GET_CORPORATE_VISITS_SUMMARY_ERROR',

  GET_WORK_SITE_MANAGER_DATA: 'GET_WORK_SITE_MANAGER_DATA',
  GET_WORK_SITE_MANAGER_ERROR: 'GET_WORK_SITE_MANAGER_ERROR',

  POST_CORPORATE_RECORD_DATA: 'POST_CORPORATE_RECORD_DATA',
  POST_CORPORATE_RECORD_ERROR: 'POST_CORPORATE_RECORD_ERROR',
  POST_CALL_RECORD_DATA: 'POST_CALL_RECORD_DATA',
  POST_CALL_RECORD_ERROR: 'POST_CALL_RECORD_ERROR',
  POST_PHONE_NUMBER_DATA: 'POST_PHONE_NUMBER_DATA',
  POST_PHONE_NUMBER_ERROR: 'POST_PHONE_NUMBER_ERROR',
  POST_WORK_ORDER_NUMBER_DATA: 'POST_WORK_ORDER_NUMBER_DATA',
  POST_WORK_ORDER_NUMBER_ERROR: 'POST_WORK_ORDER_NUMBER_ERROR',

  POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_DATA: 'POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_DATA',
  POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_ERROR: 'POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_ERROR',
  POST_CORPORATE_WORK_SITE_USING_CSV_DATA: 'POST_CORPORATE_WORK_SITE_USING_CSV_DATA',
  POST_CORPORATE_WORK_SITE_USING_CSV_ERROR: 'POST_CORPORATE_WORK_SITE_USING_CSV_ERROR',
  

  EDIT_WORK_SITE_DATA: 'EDIT_WORK_SITE_DATA',
  EDIT_WORK_SITE_ERROR: 'EDIT_WORK_SITE_ERROR',
  EDIT_CORPORATE_RECORD_DATA: 'EDIT_CORPORATE_RECORD_DATA',
  EDIT_CORPORATE_RECORD_ERROR: 'EDIT_CORPORATE_RECORD_ERROR',
  EDIT_CALL_RECORD_DATA: 'EDIT_CALL_RECORD_DATA',
  EDIT_CALL_RECORD_ERROR: 'EDIT_CALL_RECORD_ERROR',
  EDIT_PHONE_NUMBER_DATA: 'EDIT_PHONE_NUMBER_DATA',
  EDIT_PHONE_NUMBER_ERROR: 'EDIT_PHONE_NUMBER_ERROR',
  EDIT_WORK_ORDER_NUMBER_DATA: 'EDIT_WORK_ORDER_NUMBER_DATA',
  EDIT_WORK_ORDER_NUMBER_ERROR: 'EDIT_WORK_ORDER_NUMBER_ERROR',
  EDIT_CORPORATE_PASSWORD_DATA: 'EDIT_CORPORATE_PASSWORD_DATA',
  EDIT_CORPORATE_PASSWORD_ERROR: 'EDIT_CORPORATE_PASSWORD_ERROR',

  UPDATE_CHECK_IN_CALL_RECORD_DATA: 'UPDATE_CHECK_IN_CALL_RECORD_DATA',
  UPDATE_CHECK_IN_CALL_RECORD_ERROR: 'UPDATE_CHECK_IN_CALL_RECORD_ERROR',

  DELETE_WORK_SITE_DATA: 'DELETE_WORK_SITE_DATA',
  DELETE_WORK_SITE_ERROR: 'DELETE_WORK_SITE_ERROR',
  INACTIVE_WORK_SITE_DATA: 'INACTIVE_WORK_SITE_DATA',
  INACTIVE_WORK_SITE_ERROR: 'INACTIVE_WORK_SITE_ERROR',
  DELETE_CORPORATE_RECORD_DATA: 'DELETE_CORPORATE_RECORD_DATA',
  DELETE_CORPORATE_RECORD_ERROR: 'DELETE_CORPORATE_RECORD_ERROR',
  DELETE_CALL_RECORD_DATA: 'DELETE_CALL_RECORD_DATA',
  DELETE_CALL_RECORD_ERROR: 'DELETE_CALL_RECORD_ERROR',
  DELETE_PHONE_NUMBER_DATA: 'DELETE_PHONE_NUMBER_DATA',
  DELETE_PHONE_NUMBER_ERROR: 'DELETE_PHONE_NUMBER_ERROR',
  DELETE_WORK_ORDER_NUMBER_DATA: 'DELETE_WORK_ORDER_NUMBER_DATA',
  DELETE_WORK_ORDER_NUMBER_ERROR: 'DELETE_WORK_ORDER_NUMBER_ERROR',
};

export default CorporateDataActions;
