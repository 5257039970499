import QuickView from './QuickView';
import AdminData from './AdminDataReducer';
import AdminAuthData from './AdminAuthReducer';
import CorporateData from './CorporateDataReducer';
import WorkSiteData from './WorkSiteDataReducer';
import MailData from './MailDataReducer';
import { combineReducers } from 'redux';

// export default { QuickView };

const rootReducer = combineReducers({
  QuickView,
  AdminData,
  AdminAuthData,
  CorporateData,
  WorkSiteData,
  MailData,
  // Add other reducers here
});

export default rootReducer;
