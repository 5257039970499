export const tableOneData = [
    {'title':'Life’s sadness shared', 'places':'', 'activities':'UK was on top of the art world 18-19 century had the best food, best cloths and best entertainment back then) it was a hyper nation', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'First Tour', 'places':'', 'activities':'you want English, Scottish, Welsh, Irish, British, European or UK even a British (name other original country you came form or have roots to E.G. A British Japanese or a 5th generation', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Second Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Third Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Fourth Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Fifth Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Sixth Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Seventh Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Eighth Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Ninth Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Tenth Tour', 'places':'', 'activities':'it is more then ONE nation/nationality as its fall name is The United Kingdom of Great Britain and North Ireland..', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'A day to remember', 'places':'', 'activities':'he world speaks English. Common law, Magna Carta and the Bill of Rights are its wonderful legacy', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'},
    {'title':'Among the children', 'places':'', 'activities':'you want English, Scottish, Welsh, Irish, British, European or UK even a British (name other original country you came form or have roots to E.G. A British Japanese or a 5th generation', 'status':'Public', 'lastUpdate':'April 13,2014 10:13'}   
]

export const tableTwoData = [
    {'appName':'Angry Birds', 'description':'Description goes here', 'price':'FREE', 'notes':'Notes go here'},
    {'appName':'Facebook', 'description':'Description goes here', 'price':'FREE', 'notes':'Notes go here'},
    {'appName':'Foursquare', 'description':'Description goes here', 'price':'FREE', 'notes':'Notes go here'},
    {'appName':'Hyperlapse', 'description':'Description goes here', 'price':'FREE', 'notes':'Notes go here'},
    {'appName':'Twitter', 'description':'Description goes here', 'price':'FREE', 'notes':'Notes go here'}
]

export const tableThreeData = [
    {'renderingEngine':'Gecko','browser':'Firefox 1.0','platforms':'Win 98+ / OSX.2+','engineVersion':'1.7','cssGrade':'A'},
    {'renderingEngine':'Mecko','browser':'Chrome 1.0','platforms':'Win 98+','engineVersion':'2.7','cssGrade':'B'},
    {'renderingEngine':'Neko','browser':'Chrome 1.5','platforms':'Win 7','engineVersion':'1.5','cssGrade':'C'},
    {'renderingEngine':'KHTML','browser':'Safari 2.5','platforms':'Win 10','engineVersion':'1.4','cssGrade':'D'},
    {'renderingEngine':'Misc','browser':'Edge 1.7','platforms':'Win 10 / Ubuntu 18','engineVersion':'4.2','cssGrade':'E'},
    {'renderingEngine':'Lin','browser':'Firefox 1.0','platforms':'Fedora / OSX.2+','engineVersion':'1.7','cssGrade':'F'},
    {'renderingEngine':'Win','browser':'Chrome 1.0','platforms':'OSX','engineVersion':'4.7','cssGrade':'G'},
    {'renderingEngine':'Gec','browser':'Chrome 1.5','platforms':'Win 8','engineVersion':'1.7','cssGrade':'H'},
    {'renderingEngine':'DKHTML','browser':'Safari 2.5','platforms':'Win 10','engineVersion':'1.4','cssGrade':'I'},
    {'renderingEngine':'Sine','browser':'Edge 1.7','platforms':'Fedora / Ubuntu 18','engineVersion':'1.5','cssGrade':'I'},
    {'renderingEngine':'Nate','browser':'Firefox 1.0','platforms':'Win 98+ / OSX.2+','engineVersion':'1.7','cssGrade':'J'},
    {'renderingEngine':'Tre','browser':'Chrome 1.0','platforms':'Win 2000','engineVersion':'5.7','cssGrade':'K'},
    {'renderingEngine':'Secko','browser':'Chrome 1.5','platforms':'Win 7','engineVersion':'1.7','cssGrade':'L'},
    {'renderingEngine':'YHTML','browser':'Safari 2.5','platforms':'Fedora','engineVersion':'3.5','cssGrade':'M'},
    {'renderingEngine':'Risc','browser':'Edge 1.7','platforms':'Win 10 / Ubuntu 18','engineVersion':'1.2','cssGrade':'N'},
    {'renderingEngine':'Lecko','browser':'Firefox 1.0','platforms':'Ubuntu / OSX.2+','engineVersion':'1.7','cssGrade':'O'},
    {'renderingEngine':'Yecko','browser':'Chrome 1.0','platforms':'Mint','engineVersion':'2.1','cssGrade':'P'},
    {'renderingEngine':'Necko','browser':'Chrome 1.5','platforms':'Win 8','engineVersion':'1.7','cssGrade':'Q'},
    {'renderingEngine':'RhTML','browser':'Safari 2.5','platforms':'Fedora 25','engineVersion':'1.9','cssGrade':'R'},
    {'renderingEngine':'Disc','browser':'Edge 1.7','platforms':'Win 10 / Fedora 20','engineVersion':'1.3','cssGrade':'S'},
    {'renderingEngine':'Hike','browser':'Firefox 1.0','platforms':'Win 98+ / OSX.2+','engineVersion':'1.5','cssGrade':'T'},
    {'renderingEngine':'Adidas','browser':'Chrome 1.0','platforms':'Win 98+','engineVersion':'2.7','cssGrade':'U'},
    {'renderingEngine':'Duke','browser':'Chrome 1.5','platforms':'Win 10 / Ubuntu 19.1','engineVersion':'1.4','cssGrade':'V'},
    {'renderingEngine':'BMW','browser':'Safari 2.5','platforms':'Fedora','engineVersion':'1.4','cssGrade':'W'},
    {'renderingEngine':'Wate','browser':'Edge 1.7','platforms':'Win 7 / Ubuntu 18','engineVersion':'1.1','cssGrade':'X'},
    {'renderingEngine':'Time','browser':'Firefox 1.0','platforms':'Win 98+ / OSX.2+','engineVersion':'1.9','cssGrade':'Y'},
    {'renderingEngine':'Zind','browser':'Chrome 1.0','platforms':'OSX','engineVersion':'8.7','cssGrade':'Z'},
    {'renderingEngine':'Query','browser':'Chrome 1.5','platforms':'Win 10','engineVersion':'1.7','cssGrade':'AA'},
    {'renderingEngine':'Uata','browser':'Safari 2.5','platforms':'Win 7','engineVersion':'1.4','cssGrade':'CB'},
    {'renderingEngine':'Jale','browser':'Edge 1.7','platforms':'Win 10 / Ubuntu 18','engineVersion':'7.2','cssGrade':'BS'},
    {'renderingEngine':'Ceen','browser':'Firefox 1.0','platforms':'Win 98+ / OSX.2+','engineVersion':'1.7','cssGrade':'ADD'},
    {'renderingEngine':'yinz','browser':'Chrome 1.0','platforms':'Fedora','engineVersion':'2.7','cssGrade':'BW'},
    {'renderingEngine':'Xeon','browser':'Chrome 1.5','platforms':'Ubuntu','engineVersion':'6.7','cssGrade':'AA'},
    {'renderingEngine':'Dew','browser':'Safari 2.5','platforms':'Mint','engineVersion':'1.4','cssGrade':'CS'},
    {'renderingEngine':'Test','browser':'Edge 1.7','platforms':'Mint / Ubuntu 18','engineVersion':'5.2','cssGrade':'BR'},
    {'renderingEngine':'Mine','browser':'Firefox 1.0','platforms':'Fedora / OSX.2+','engineVersion':'4.7','cssGrade':'AS'},
    {'renderingEngine':'Queue','browser':'Chrome 1.0','platforms':'Win 10','engineVersion':'3.7','cssGrade':'BG'},
    {'renderingEngine':'Wild','browser':'Chrome 1.5','platforms':'Win 7','engineVersion':'2.7','cssGrade':'AH'},
    {'renderingEngine':'Ant','browser':'Safari 2.5','platforms':'Win 8','engineVersion':'1.4','cssGrade':'HC'},
    {'renderingEngine':'DMisc','browser':'Edge 1.7','platforms':'Win 10 / Ubuntu 18','engineVersion':'1.2','cssGrade':'GGB'},
]
 