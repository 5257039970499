import React, { Component } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Casual from "./Theme/Casual";
import Corporate from "./Theme/Corporate";
import Condensed from "./Theme/Condensed";
import Simple from "./Theme/simple";
import Executive from "./Theme/Executive";

import Quickview from "./components/Quickview";

import AuthenticateRoute from "./utils/validations/authValidation";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
	render() {
		return (
			<BrowserRouter basename="/">
				<div className="App h-100">
				<ToastContainer 
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					// closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
					style={{zIndex: "99999"}}
					/>
					<Routes>
						{/* Uncomment the routes below if you want to view all the themes (remember to uncomment the imports as well*/}
						{/* <Route path="/casual/*" element={<Casual />}>

						</Route>
						<Route path="/corporate/*" element={<Corporate />}>

						</Route>
						<Route path="/condensed/*" element={<Condensed />}>

						</Route> */}
						<Route path="/*" element={<AuthenticateRoute><Simple /></AuthenticateRoute>}>

						</Route>
						{/* <Route path="/executive/*" element={<Executive />}>

						</Route> */}
					</Routes>
					<Quickview />
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
