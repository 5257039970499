import { toast } from "react-toastify";
import { resetSetUserType, resetSetUserId } from "../api-actions/adminAuthActions";

export const handleLogout = (dispatch, navigate, logoutMessage) => {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('adminName');
  localStorage.removeItem('tokenExpirationTime');
  dispatch(resetSetUserType());
  dispatch(resetSetUserId());

  // Check if the items are removed
  const isJwtTokenRemoved = localStorage.getItem('jwtToken') === null;
  const isAdminNameRemoved = localStorage.getItem('adminName') === null;
  const istokenExpirationTimeRemoved = localStorage.getItem('tokenExpirationTime') === null;

  if (isJwtTokenRemoved && isAdminNameRemoved && istokenExpirationTimeRemoved) {
    toast.success(logoutMessage);

    setTimeout(() => {
      navigate('/login');
    }, 1000);
  } else {
    toast.error('Error during logout, please try again.');
  }
};
