import React from 'react'
import PageContainer from '../UIElements/Containers';

const Dashboard = () => {
  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}
          
          <PageContainer className="bg-white container">
            <h1>Dashboard</h1>
          </PageContainer>
        </div>
      </div>
    </div>
  )
}

export default Dashboard