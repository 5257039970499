export const tabSelectOne = [
    { value: '0', label: 'Realtime Charts' },
    { value: '1', label: 'Stacked Bar Charts' },
    { value: '2', label: 'Area Chart With Slider' }
]

export const tabSelectTwo = [
    { value: '0', label: 'Line Chart' },
    { value: '1', label: 'Stacked Area Charts' },
    { value: '2', label: 'Line Charts With Area' }
]