import React from 'react';
import { useGoogleMaps } from "react-hook-google-maps";

const MapContainer = () => {

  const { ref } = useGoogleMaps(
    "AIzaSyDkUccUhnqI9NbZP3_eIpQmAyrS4JNnAkU",
    {
      zoom : 11,
      center : { lat: 40.67, lng: -73.94 },
      disableDefaultUI : true,
      styles : [
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              hue: '#e9ebed'
            },
            {
              saturation: -78
            },
            {
              lightness: 67
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              hue: '#ffffff'
            },
            {
              saturation: -100
            },
            {
              lightness: 100
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#bbc0c4'
            },
            {
              saturation: -93
            },
            {
              lightness: 31
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              hue: '#ffffff'
            },
            {
              saturation: -100
            },
            {
              lightness: 100
            },
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#e9ebed'
            },
            {
              saturation: -90
            },
            {
              lightness: -8
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              hue: '#e9ebed'
            },
            {
              saturation: 10
            },
            {
              lightness: 69
            },
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'all',
          stylers: [
            {
              hue: '#2c2e33'
            },
            {
              saturation: 7
            },
            {
              lightness: 19
            },
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              hue: '#bbc0c4'
            },
            {
              saturation: -93
            },
            {
              lightness: 31
            },
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels',
          stylers: [
            {
              hue: '#bbc0c4'
            },
            {
              saturation: -93
            },
            {
              lightness: -2
            },
            {
              visibility: 'simplified'
            }
          ]
        }
      ]
    },
  );
  
  return <div ref={ref} style={{ width: '100%', height: '100vh' }} />;
};

export default MapContainer;